import React from "react";
import CandidateSaveForm from "./CandidateSaveForm";

export default function CandidateCreateForm(props) {
  const { recruiterId, jobId, onChangeStep } = props;

  // Make a fake job
  const candidate = {
    recruiterId,
  };

  if (jobId) {
    return (
      <CandidateSaveForm
        recruiterId={recruiterId}
        jobId={jobId}
        candidate={candidate}
        onChangeStep={onChangeStep}
      />
    );
  } else {
    return (
      <CandidateSaveForm
        recruiterId={recruiterId}
        candidate={candidate}
        onChangeStep={onChangeStep}
      />
    );
  }
}
