import React from "react";
import Page from "../common/page/Page";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationFooter from "./useTranslationFooter";
import ActionButton from "../common/widgets/ActionButton";
import HtmlUtils from "../../utils/HtmlUtils";
import PricingSection from "../employer-landing/view/PricingSection";
import "./PricingPage.scss";
import RouterUtils from "../../utils/RouterUtils";
import { CALENDLY_URL } from "../../constants/routes";

export default function PricingPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  const onClickDemo = (event) => {
    event.preventDefault();
    RouterUtils.openExternalUrl(CALENDLY_URL);
  };

  return (
    <Page loading={tLoading} className="PricingPage">
      <Page.Content>
        <Col className="pricing-page-col">
          <Row>
            <Col className="pricing-col">
              <Row>
                <Col className="our-story-title">
                  <h1>{t("footer:footer_pricing_title")}</h1>
                </Col>
              </Row>
              <Row className="story-row">
                <Col>
                  {HtmlUtils.htmlToComponents(t("footer:footer_pricing_text"))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <PricingSection />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="book-demo-row">
                <Row className="book-demo-col">
                  <Col className="book-demo-row-container">
                    <Row>
                      <Col>
                        <h2>{t("footer:footer_book_demo_title")}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ActionButton
                          onClick={onClickDemo}
                          className="action-button"
                        >
                          {t("footer:footer_book_demo_button_label")}
                        </ActionButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Page.Content>
    </Page>
  );
}
