import React from "react";
import "./NotFoundPage.scss";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationApp from "./useTranslationApp";
import notFoundImg from "../../img/page-not-found.svg";
import { Image } from "react-bootstrap";

export default function NotFoundPage() {
  // RENDER
  const { t, loading: tLoading } = useTranslationApp(useShowLoader);

  useShowLoader(tLoading, "NotFoundPage");

  return (
    <div className="NotFoundPage">
      <Image src={notFoundImg} />
      <div>{t("app:notfound_content")}</div>
    </div>
  );
}
