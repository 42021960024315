import React from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import StringUtils from "../../utils/StringUtils";
import FormText from "react-bootstrap/FormText";
import ActionButton from "../common/widgets/ActionButton";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import useSignIn from "../authentication-no-ui/useSignIn";
import * as ROUTES from "../../constants/routes";
import useReferrer from "../infra-no-ui/navigation/useReferrer";
import { Redirect, useHistory } from "react-router-dom";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SignInWithAuthProvider from "./SignInWithAuthProvider";
import ActionLink from "../common/widgets/ActionLink";
import Separator from "../common/widgets/Separator";
import ValidatedInputEmail from "../common/widgets/ValidatedInputEmail";
import ValidatedInputText from "../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm";
import { MIN_LENGTH_PASSWORD } from "../../constants/ui";
import { useTranslation } from "react-i18next";
import "./SignInForm.scss";

export default function SignInForm() {
  const { signIn, loading, error, ready: signInReady } = useSignIn();
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const { referrer } = useReferrer(ROUTES.RECRUITER_DASHBOARD);
  const history = useHistory();
  useShowLoader(tLoading || !signInReady, "SignInForm");
  const { i18n } = useTranslation();

  const onClickSignUp = (event) => {
    event.preventDefault();
    history.push(ROUTES.SIGN_UP);
  };

  const onClickResetPassword = (event) => {
    event.preventDefault();
    history.push(ROUTES.RESET_PASSWORD);
  };

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const onSubmit = (event) => {
    event.preventDefault();
    const fields = getValues();

    signIn(fields.email.trim(), fields.password);
  };

  const canSubmit = () => {
    return isValid;
  };

  React.useEffect(() => {
    // Force the default user language after login
    if (authUser.authenticated) {
      i18n.changeLanguage(
        authUser.profile.defaultLanguage.toLowerCase(),
        (/*err*/) => {
          // Do nothing if an error occurs
        }
      );
    }
  }, [authUser, i18n]);

  // RENDER

  // If already logged, or when rendering after sign in, do not display this page, redirect to referrer or HOME
  if (authUser.authenticated) {
    return <Redirect to={referrer} />;
  }

  function renderForm() {
    // For some reason that is internal to Firebase, StyledFirebaseAuth rendered by SignInWithAuthProvider does not re-render
    // when the button labels change, so make sure the form is not displayed until the translation is ready.
    // A more robust solution needs to be found because labels still don't change when language changes
    if (tLoading) return null;

    return (
      <div className="SignInForm">
        <div>
          <Row>
            <Col>
              <SignInWithAuthProvider
                googleButtonLabel={t("authentication-ui:sign_in_with_google")}
                facebookButtonLabel={t(
                  "authentication-ui:sign_in_with_facebook"
                )}
                target={referrer.pathname}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="separator-col">
            <Separator>
              {t("authentication-ui:sign_in_with_email_msg")}
            </Separator>
          </Col>
        </Row>
        <Row className="input-row-container">
          <Col className="input-col-container">
            <Form onSubmit={onSubmit} className={"form-with-rows"}>
              <FormGroup controlId="email">
                <ValidatedInputEmail
                  required={true}
                  name="email"
                  control={control}
                  className="FormControl"
                  autoFocus
                  type="email"
                  placeholder={t("authentication-ui:sign_in_email_placeholder")}
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup controlId="password">
                <ValidatedInputText
                  minLength={MIN_LENGTH_PASSWORD}
                  className="FormControl"
                  control={control}
                  required={true}
                  name="password"
                  type="password"
                  placeholder={t(
                    "authentication-ui:sign_in_password_placeholder"
                  )}
                  autoComplete="current-password"
                />
              </FormGroup>
              <FormGroup>
                <Form.Row className="form-control-row">
                  <ActionButton
                    loading={loading}
                    type="submit"
                    disabled={!canSubmit()}
                  >
                    {t("authentication-ui:sign_in_sign_in_button")}
                  </ActionButton>
                </Form.Row>
              </FormGroup>
              {!StringUtils.isNullOrEmpty(error) && (
                <FormText as="div" className="form-error">
                  {error}
                </FormText>
              )}
            </Form>
          </Col>
        </Row>
        <Row className="action-links-row">
          <Col className="action-links-col">
            <Row>
              <Col className="forgot-col">
                <ActionLink onClick={onClickResetPassword}>
                  {t("authentication-ui:sign_in_forgot_password_link")}
                </ActionLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {t("authentication-ui:sign_in_sign_up_question")}
            <ActionLink className="sign-up-action" onClick={onClickSignUp}>
              {t("authentication-ui:sign_in_sign_up_link")}
            </ActionLink>
          </Col>
        </Row>
      </div>
    );
  }

  return <div className="SignInForm">{renderForm()}</div>;
}
