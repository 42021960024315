import React from "react";
import RouterUtils from "../../../utils/RouterUtils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import ActionButton from "../../common/widgets/ActionButton";
import ApplicationItem from "../view/ApplicationItem";
import "./ApplicationSearchResult.scss"

export default function ApplicationSearchResult(props) {
  const { item: application } = props;

  const history = useHistory();

  function onClickCandidate(event, candidateId) {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
        candidateId,
      })
    );
  }

  return (
    <ActionButton
      as={"div"}
      variant="outline-primary"
      onClick={(event) => onClickCandidate(event, application.candidateId)}
      className={"ApplicationSearchResult"}
    >
      <ApplicationItem application={application} />
    </ActionButton>
  );
}
