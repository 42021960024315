import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import StringUtils from "../../utils/StringUtils";
import ActionButton from "../common/widgets/ActionButton";
import FormText from "react-bootstrap/FormText";
import useMutationRecruiterAdd from "./useMutationRecruiterAdd";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import useSignOut from "../authentication-no-ui/useSignOut";
import Modal from "react-bootstrap/Modal";
import useServerErrorFormatter from "../common/modals/useServerErrorFormatter";
import ValidatedInputText from "../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm";
import ActionLink from "../common/widgets/ActionLink";
import useLanguages from "../infra-no-ui/translation/useLanguages";
import Image from "react-bootstrap/Image";
import add from "../../img/add.png";
import "./ProfileCreationForm.scss";
import useSponsorCode from "../sponsorship/useSponsorCode";
import TypeUtils from "../../utils/TypeUtils";

export default function ProfileCreationForm() {
  const [errors, setErrors] = React.useState([]);

  // Get used language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const [loading, setLoading] = React.useState(false);
  const { mutate, errors: mutationErrors } = useMutationRecruiterAdd();
  const {
    signOut,
    loading: signOutLoading,
    ready: signOutReady,
  } = useSignOut();
  const { t, tLoading } = useTranslationAuthenticationUi();

  const sponsorCode = useSponsorCode();

  const authUser = useAuthUser();
  const errorMessage = useServerErrorFormatter(errors);
  useShowLoader(
    tLoading || !signOutReady || signOutLoading,
    "ProfileCreationForm"
  );

  const name = StringUtils.nullToEmpty(authUser.displayName);

  const firstName = name.split(" ")[0];
  const lastName = name.split(" ").length > 1 ? name.split(" ")[1] : "";

  const onSubmit = (event) => {
    event.preventDefault();
    const fields = getValues();
    // Manage the loading state manually, don't tie it to the mutation, because we want to control
    // (we want it to stay loading until the user refetch is done)
    setLoading(true);
    setErrors([]);

    // When creating profile, use email as returned by Firebase because it lowercases it
    return mutate({
      variables: {
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: authUser.email,
        sponsoredByCode: sponsorCode,
        defaultLanguage: currentLanguageCode.toUpperCase(),
      },
    })
      .then(() => {
        // Refetch complete user, don't stop being in a loading state until refetch is done
        authUser.refetch();
      })
      .catch(() => {

        if (!TypeUtils.arrayIsEmpty(mutationErrors) && mutationErrors[0].name === 'DuplicatedProfileError') {
          // If error is a duplicate profile, just reload the page, the existing profile will be used
          authUser.refetch();
        } else {
          // Display error and let the user resubmit
          setErrors(mutationErrors);
          setLoading(false);
        }
      });
  };

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const onClickSignOut = (event) => {
    event.preventDefault();
    signOut();
  };

  // RENDER

  return (
    <Modal
      centered
      show={true}
      size={"lg"}
      backdrop={"static"}
      keyboard={false}
      onHide={signOut}
      className="ProfileCreationForm"
    >
      <Modal.Body className="body-container">
        <Row>
          <Col>
            <Image src={add} className="picture" />
          </Col>
        </Row>
        <Row>
          <Col className="profile-message">
            {t("authentication-ui:missing_profile_msg")}
          </Col>
        </Row>
        <Row>
          <Col className="input-col-container">
            <Form onSubmit={(event) => onSubmit(event)}>
              <FormGroup controlId="firstName">
                <ValidatedInputText
                  name="firstName"
                  control={control}
                  required={true}
                  defaultValue={firstName}
                  className="FormControl"
                  autoFocus
                  placeholder={t(
                    "authentication-ui:sign_up_firstName_placeholder"
                  )}
                  autoComplete="firstName"
                />
              </FormGroup>
              <FormGroup controlId="lastName">
                <ValidatedInputText
                  name="lastName"
                  className="FormControl"
                  control={control}
                  defaultValue={lastName}
                  required={true}
                  placeholder={t(
                    "authentication-ui:sign_up_lastName_placeholder"
                  )}
                  autoComplete="lastName"
                />
              </FormGroup>
              <Row className="action-row-container">
                <Col className="continue-action">
                  <ActionButton
                    loading={loading}
                    type="submit"
                    disabled={!canSubmit()}
                  >
                    {t("authentication-ui:missing_profile_continue_action")}
                  </ActionButton>
                </Col>
                <Col className="sign-out-action">
                  <ActionLink onClick={onClickSignOut}>
                    {t("authentication-ui:sign_up_sign_out_action")}
                  </ActionLink>
                </Col>
              </Row>
              {errorMessage && (
                <FormText as="div" className="form-error">
                  {errorMessage}
                </FormText>
              )}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
