import React from "react";
import Page from "../../common/page/Page";
import FaqSection from "./FaqSection";
import useTranslationLanding from "../useTranslationLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function HowItWorksPage(props) {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "LandingPage");

  return (
    <Page className="LandingPage">
      <Page.Title text={t("landing:landing_faq_title")} loading={tLoading} ><h1>{t("landing:landing_faq_title")}</h1></Page.Title>
      <Page.Content className="spaced-children">
        <FaqSection />
      </Page.Content>
    </Page>
  );
}
