import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import "./CandidateSearchResult.scss";
import ActionButton from "../../common/widgets/ActionButton";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import DateUtils from "../../../utils/DateUtils";

export default function CandidateSearchResult(props) {
  const { item: candidate, onClose } = props;

  const history = useHistory();
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateSearchResult");

  const location = useLocation();

  function onClickCandidate(event, candidateId) {
    event.preventDefault();
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        step: "CANDIDATE_INFORMATION",
        candidateId,
      }),
    });
    onClose();
  }

  return (
    <ActionButton
      as={"div"}
      variant="outline-primary"
      onClick={(event) => onClickCandidate(event, candidate._id)}
      className={"CandidateSearchResult"}
    >
      <Row>
        <Col className={"name-col"}>{candidate.name}</Col>
        <Col className={"name-col"}>
          {t("candidates:candidates_details_creation_period_days", {
            count: DateUtils.dateDiff(Date.parse(candidate.updatedAt)),
          })}
        </Col>
      </Row>
    </ActionButton>
  );
}
