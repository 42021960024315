import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import cssVariables from "./Charts.module.scss";
import "./BarChart.scss";

const INDEX_AXIS = "y";
const FONT_WEIGHT = cssVariables.fontWeight;
const FONT_SIZE = cssVariables.fontSize;
const FONT_COLOR = cssVariables.fontColor;

// Chart customization
export const BarChart = (props) => {
  const { data, displayLegend = true } = props;

  const options = {
    maintainAspectRatio: false,
    indexAxis: INDEX_AXIS,
    plugins: {
      legend: {
        display: displayLegend,
      },
      tooltip: {
        callbacks: {
          label: (item) =>
            `${Number.parseFloat(item.formattedValue).toFixed(2)}%`,
        },
        displayColors: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          autoSkip: false,
          font: {
            size: FONT_SIZE,
            weight: FONT_WEIGHT,
          },
          color: FONT_COLOR,
        },
      },
      x: {
        display: false,
      },
    },
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

  return (
    <div className="bar-chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
