import React from "react";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionLink from "../../common/widgets/ActionLink";
import ActionButton from "../../common/widgets/ActionButton";
import StringUtils from "../../../utils/StringUtils";
import useErrorModal from "../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useCandidateSaveInformationForm from "./useCandidateSaveInformationForm";
import useCandidateSaveResumeForm from "./useCandidateSaveResumeForm";
import useCandidateSaveApplicationForm from "./useCandidateSaveApplicationForm";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import useSuccessModal from "../../common/modals/useSuccessModal";
import RouterUtils from "../../../utils/RouterUtils";
import "./CandidateSaveForm.scss";

export const STEP_ENUM = {
  CANDIDATE_INFORMATION: "CANDIDATE_INFORMATION",
  RESUME: "RESUME",
  NOTES: "NOTES",
};

export default function CandidateSaveForm(props) {
  const { recruiterId, jobId, candidate, step, onChangeStep } = props;

  const { t, loading } = useTranslationCandidates();
  useShowLoader(loading);

  const history = useHistory();

  // Label of the last action
  const saveLastAction = StringUtils.isNullOrEmpty(jobId)
    ? t("candidates:candidates_create_candidate_save_last_action")
    : t("candidates:candidates_save_last_action");

  // Define all the steps of the form
  const stepDefinitions = [{
      name: STEP_ENUM.CANDIDATE_INFORMATION,
      label: t("candidates:candidates_save_candidate_information_step_label"),
      ...useCandidateSaveInformationForm(candidate, recruiterId, jobId),
      visible: true
    }, {
      name: STEP_ENUM.RESUME,
      label: t("candidates:candidates_save_candidate_resume_step_label"),
      ...useCandidateSaveResumeForm(candidate),
      visible: true
    }, {
      name: STEP_ENUM.NOTES,
      label: t("candidates:candidates_save_candidate_note_step_label"),
      ...useCandidateSaveApplicationForm(candidate, recruiterId, jobId),
      visible: !StringUtils.isNullOrEmpty(jobId)
    }
  ];

  /**
   * Get the index of the nearest visible step (from stepDefinitions) before the step index provided.
   * The current position is included in the search.
   * @param position Start search at this index
   * @returns {number|*} Index of the nearest visible step before provided position, or -1 if no visible step found
   */
  const getPreviousVisibleStepIndex = (position) => {
    // Move backward from position (including current) until step is visible
    for (let i = position; i >= 0 && i < stepDefinitions.length; i--) {
      if (stepDefinitions[i].visible) {
        return i;
      }
    }

    // We have reached index 0 and haven't encountered a visible step, so return -1 (not found)
    return -1;
  }

  /**
   * Get the index of the nearest visible step (from stepDefinitions) after the step index provided.
   * The current position is included in the search.
   * @param position Start search at this index
   * @returns {number|*} Index of the nearest visible step after provided position, or -1 if no visible step found
   */
  const getNextVisibleStepIndex = (position) => {
    // Move forward from position (including current position) until step is visible
    for (let i = position; i >= 0 && i < stepDefinitions.length; i++) {
      if (stepDefinitions[i].visible) {
        return i;
      }
    }

    // We have reached the maximum index and haven't encountered a visible step, so return -1 (not found)
    return -1;
  }

  /**
   * Get the index of the step (from stepDefinitions) whose name is the one provided. The step may be visible or not.
   * If name is empty, return -1.
   * @param name Step name
   * @returns {number} Step index
   */
  const getStepIndexByName = (name) => {
    if (StringUtils.isNullOrEmpty(name))
      return -1;
    return stepDefinitions.findIndex((stepDefinition) => stepDefinition.name === name.toUpperCase());
  }

  // Find target step index and definition from step name provided in properties. If step is empty / not found, use step 0.
  // If step is not visible, use the nearest visible step before (or step 0 if none found).
  const currentStepIndex = Math.max(
    0,
    getPreviousVisibleStepIndex(getStepIndexByName(step))
  );

  const currentStepDefinition = stepDefinitions[currentStepIndex];

  // Candidate and recommendation are not modified using this multistep form, so we don't have to persist the last saved step
  // with the candidate or recommendation in the DB because it is used only at creation time
  const [lastSavedStepIndex, setLastSavedStepIndex] = React.useState(getPreviousVisibleStepIndex(currentStepIndex - 1));
  const [lastVisitedStepIndex, setLastVisitedStepIndex] = React.useState(Math.max(currentStepIndex, lastSavedStepIndex));

  React.useEffect(() => {
    if (lastVisitedStepIndex < currentStepIndex)
      setLastVisitedStepIndex(currentStepIndex);
  }, [currentStepIndex, lastVisitedStepIndex]);

  // Form is not ready for display until the form content is ready
  const ready = currentStepDefinition.ready;

  // It is an error to display a step other than the first one if there is no existing candidate
  const candidateNotFound =
    currentStepIndex !== 0 && !candidate._id ? (
      <p>{t("candidates:candidates_save_no_candidate")}</p>
    ) : null;

  // Return error message
  const getErrorMessage = (code) => {
    switch (code) {
      case "DuplicatedCandidateError":
        return t(
          "candidates:candidates_server_duplicated_candidate_error_message"
        );
      case "MultipleFilesSelectedError":
        return t(
          "candidates:candidates_resume_multiple_files_error"
        );
      case "MaxFileSizeError":
        return t(
          "candidates:candidates_resume_file_size_error"
        );
      case "FileUploadError":
        return t(
          "candidates:candidates_details_file_upload_error"
        );
      case "UnsupportedMediaTypeError":
        return t(
          "candidates:candidates_unsupported_file_type_error"
        );
      case "MissingQualificationError":
        return t(
          "candidates:candidates_save_application_qualifications_error"
        );
      default:
        return t("candidates:candidates_server_error_message");
    }
  };

  const serverErrorMessage = currentStepDefinition.errors
    ? getErrorMessage(currentStepDefinition.errors[0].name)
    : null;

  const submitError = useServerErrorFormatter(
    currentStepDefinition.errors,
    serverErrorMessage,
    false
  );

  const error = candidateNotFound || submitError;
  const { ErrorModal, show: showErrorModal } = useErrorModal(<>{error}</>);

  // Get name of previous visible step (excluding current position)
  const getPrevStepName = () => {
    const previousVisibleStepIndex = getPreviousVisibleStepIndex(currentStepIndex - 1);
    return previousVisibleStepIndex === -1 ? "" : stepDefinitions[previousVisibleStepIndex].name;
  };

  // Get name of next visible step (excluding current position)
  const getNextStepName = () => {
    const nextVisibleStepIndex = getNextVisibleStepIndex(currentStepIndex + 1);
    return nextVisibleStepIndex === -1 ? "" : stepDefinitions[nextVisibleStepIndex].name;
  };

  const prevStepName = getPrevStepName();
  const nextStepName = getNextStepName();

  // Tell the parent that the step has changed, in case it wants to change the url
  const onClickStep = (event, stepName) => {
    event.preventDefault();
    onChangeStep(stepName, candidate._id);
  };

  // Make a link to reach a step directly without using Previous and Next buttons
  const makeStep = (stepDefinition, stepIndex) => {

    const stepLabel = stepDefinition.label;

    const isCurrent = stepIndex === currentStepIndex;
    const isVisited = stepIndex <= lastVisitedStepIndex;
    const isSaved = stepIndex <= lastSavedStepIndex;

    const progressCount = isSaved ? <span className="progress-count"/> : <span className="progress-count-number">{stepIndex + 1}</span>;

    if (isCurrent) {
      // Current step is highlighted and not clickable
      return (
        <>
          {progressCount}
          <span className={"current-step-link progress-label"}>
            {stepLabel}
          </span>
        </>
      );
    } else if (!isVisited) {
      // The steps not visited yet do not have a link
      return (
        <>
          {progressCount}
          <span className="progress-label">{stepLabel}</span>
        </>
      );
    } else {
      // Steps previously visited have a link
      return (
        <>
          {progressCount}
          <span className="progress-span">
            <ActionLink
              className="progress-action"
              onClick={(event) => onClickStep(event, stepDefinition.name)}
            >
              {stepLabel}
            </ActionLink>
          </span>
        </>
      );
    }
  };

  const onClickPrevStep = (event) => {
    event.preventDefault();
    onChangeStep(prevStepName, candidate._id);
  };

  const onClickNextStep = (event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then((candidate) => {
        if (lastSavedStepIndex < currentStepIndex)
          setLastSavedStepIndex(currentStepIndex);

        return onChangeStep(nextStepName, candidate._id)
      })
      .catch(() => {
        // Error display is managed by an effect so that we can handle errors in resume upload as well
      });
  };

  // Display an error modal as soon as an error is returned by any step, at any time
  React.useEffect(() => {
    if (error)
      showErrorModal();
  }, [error, showErrorModal]);

  const onRecommendSuccessShowModalAbort = () => {
    // Redirect to CANDIDATES when done
    if (jobId) {
      return history.push(
        RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
          candidateId: candidate._id,
        })
      );
    } else {
      return history.push(ROUTES.RECRUITER_JOBS);
    }
  };

  const onClickClearCandidate = () => {
    if (StringUtils.isNullOrEmpty(jobId)) {
      // We're creating a candidate: clear means going to a brand new candidate creation form
      history.push(
        RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_CREATE, {
          candidateId: candidate._id,
        })
      );
    } else {
      // We're recommending a candidate: clear means going to a brand new recommendation form for the same job
      history.push(
        RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_RECOMMEND, {
          jobId,
        })
      );
    }
  };

  // Modal to show a success message after the application is sent or candidate is added
  const recommendSuccessMessage = jobId ? (
    <div>{t("candidates:candidates_application_approval_message")}</div>
  ) : (
    <div>{t("candidates:candidates_add_candidate_success_message")}</div>
  );
  const {
    SuccessModal: RecommendSuccessShowModal,
    show: showRecommendSuccessShowModal,
  } = useSuccessModal(
    recommendSuccessMessage,
    onRecommendSuccessShowModalAbort
  );

  const onClickLastStep = (event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then(() => {
        showRecommendSuccessShowModal();
      })
      .catch(() => {
        // Error display is managed by an effect so that we can handle errors in resume upload as well
      });
  };

  return (
    <>
      {ErrorModal}
      {RecommendSuccessShowModal}
      <form onSubmit={ (event) => onClickNextStep(event)} className="CandidateSaveForm form-with-rows">
        <Row>
          <div className="step-div">
            <Col className="step-wizard">
              <ul className={"step-wizard-list"}>
                {stepDefinitions.filter(stepDefinition => stepDefinition.visible).map((stepDefinition, stepIndex) => (
                  <li key={stepIndex} className={"step-wizard-item"}>
                    {makeStep(stepDefinition, stepIndex)}
                  </li>
                ))}
              </ul>
            </Col>
          </div>
        </Row>
        <Row>
          <Col className={StringUtils.nullToEmpty(currentStepDefinition.className)}>{currentStepDefinition.form}</Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            {candidate._id && currentStepDefinition.name === STEP_ENUM.CANDIDATE_INFORMATION && (
              <ActionButton onClick={onClickClearCandidate}>
                {t("candidates:candidates_clear_action")}
              </ActionButton>
            )}
            {!StringUtils.isNullOrEmpty(prevStepName) && (
              <ActionButton onClick={onClickPrevStep}>
                {t("candidates:candidates_save_previous_action")}
              </ActionButton>
            )}
            {StringUtils.isNullOrEmpty(nextStepName) ? (
              <ActionButton
                onClick={onClickLastStep}
                loading={currentStepDefinition.submitting}
                disabled={!currentStepDefinition.canSubmit() || !ready}
              >
                {saveLastAction}
              </ActionButton>
            ) : (
              <ActionButton
                type="submit"
                loading={currentStepDefinition.submitting}
                disabled={!currentStepDefinition.canSubmit() || !ready}
              >
                {t("candidates:candidates_save_next_action")}
              </ActionButton>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
}
