import Page from "../../common/page/Page";
import InsightsSearchBlock from "./InsightsSearchBlock";
import CommonSeo from "../../common/seo/CommonSeo";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";

const InsightsSearchPage = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsSearchPage");

  const domainName = window.location.hostname;
  const url =
    domainName +
    RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL);

  return (
    <Page className="InsightsSearchPage">
      <CommonSeo
        title={t("insights:insights_search_page_title_message")}
        description={t("insights:insights_search_page_description_message")}
        url={url}
      />
      <Page.Content className="spaced-children">
        <InsightsSearchBlock />
      </Page.Content>
    </Page>
  );
};

export default InsightsSearchPage;
