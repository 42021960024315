import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StatusDisplay from "../../common/widgets/StatusDisplay";
import CandidateApprovalStatusEnum from "../CandidateApprovalStatusEnum";
import JobStatusEnum from "../../jobs/JobStatusEnum";
import ApplicationStatusEnum from "../ApplicationStatusEnum";
import useTranslationCandidates from "../useTranslationCandidates";

export default function DisplayApplicationStatus(props) {
  const { application, job } = props;

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading);

  const statusLabel = {
    STACK: t("candidates:candidates_application_status_stack_label"),
    REJECTED: t("candidates:candidates_application_status_rejected_label"),
    SHORTLIST: t("candidates:candidates_application_status_shortlist_label"),
    INTERVIEW: t("candidates:candidates_application_status_interview_label"),
    HIRED: t("candidates:candidates_application_status_hired_label"),
  };

  const getApplicationStatus = () => {
    if (
      job.status !== JobStatusEnum.ARCHIVED ||
      application.status === ApplicationStatusEnum.HIRED
    ) {
      if (
        application.candidateApprovalStatus ===
        CandidateApprovalStatusEnum.APPROVED
      ) {
        if (
          application.viewed ||
          application.status !== ApplicationStatusEnum.STACK
        ) {
          return (
            <StatusDisplay
              status={statusLabel[application.status]}
              className="status"
            />
          );
        } else {
          return (
            <StatusDisplay
              status={t("candidates:candidates_application_status_not_viewed_label")}
              className="status"
            />
          );
        }
      } else
        return (
          <StatusDisplay
            status={t("candidates:candidates_application_status_needs_approval_label")}
            className="status"
          />
        );
    } else {
      return (
        <StatusDisplay
          status={t("candidates:candidates_application_status_closed_job_label")}
          className="status"
        />
      );
    }
  };

  return (
    <div className="DisplayApplicationStatus">{getApplicationStatus()}</div>
  );
}
