import React from "react";
import CandidateSaveForm from "./CandidateSaveForm";
import useQueryCandidateById from "../view/useQueryCandidateById";
import WaitForData from "../../common/data-fetching/WaitForData";

export default function CandidateUpdateForm(props) {
  const { candidateId, recruiterId, jobId, step, onChangeStep } = props;

  const { data: candidate, loading, errors } = useQueryCandidateById(
    candidateId
  );
  if (jobId) {
    return (
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <CandidateSaveForm
            candidate={candidate}
            recruiterId={recruiterId}
            jobId={jobId}
            step={step}
            onChangeStep={onChangeStep}
          />
        )}
      />
    );
  } else {
    return (
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <CandidateSaveForm
            candidate={candidate}
            recruiterId={recruiterId}
            step={step}
            onChangeStep={onChangeStep}
          />
        )}
      />
    );
  }
}
