import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlaceIcon from "@material-ui/icons/Place";
import BusinessIcon from "@material-ui/icons/Business";
import DateRangeIcon from "@material-ui/icons/DateRange";
import StringUtils from "../../../utils/StringUtils";
import {
  formatRequiredTechnicalSkills,
  TechnicalSkillMatchSection,
} from "../resume-matching/TechnicalSkillMatchSection";
import {
  formatRequiredSoftSkills,
  SoftSkillMatchSection,
} from "../resume-matching/SoftSkillMatchSection";
import HtmlUtils from "../../../utils/HtmlUtils";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import RouterUtils from "../../../utils/RouterUtils";
import DisplayDate from "../../common/widgets/DisplayDate";
import ActionButton from "../../common/widgets/ActionButton";
import "./ApprovalDetailsJob.scss";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum";
import TypeUtils from "../../../utils/TypeUtils";

export default function ApprovalDetailsJob(props) {
  const { job, applicationId, approval } = props;

  const history = useHistory();
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateApprovalBlock");

  const onClickApproval = () => {
    history.push(
      RouterUtils.injectParamsInRoute(
        ROUTES.RECRUITER_CANDIDATE_APPROVED_APPLICATION,
        {
          jobId: job._id,
          applicationId: applicationId,
        }
      )
    );
  };

  const requiredTechnicalSkills = formatRequiredTechnicalSkills(
    job.technicalSkills
  );

  const requiredSoftSkills = formatRequiredSoftSkills(job.softSkills);

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  return (
    <>
      <div className="ApprovalDetailsJob">
        <Row>
          <Col>
            <h1>{job.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="business-block">
            <Row>
              <Col className="icon-col">
                <BusinessIcon />
              </Col>
              <Col>{job.organization && job.organization.company}</Col>
            </Row>
          </Col>
          {!StringUtils.isNullOrEmpty(addressLine) && (
            <Col className="place-block">
              <Row>
                <Col className="icon-col">
                  <PlaceIcon />
                </Col>
                <Col>{addressLine}</Col>
              </Row>
            </Col>
          )}
          <Col className="date-block">
            <Row>
              <Col className="icon-col">
                <DateRangeIcon />
              </Col>
              <Col>
                <DisplayDate date={job.createdAt} />
              </Col>
            </Row>
          </Col>
        </Row>

        {requiredTechnicalSkills.length > 0 && (
          <>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col id="TechnicalSkill" className="section-title">
                    {t("jobs:job_technical_skills_label")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TechnicalSkillMatchSection
                      allSkills={requiredTechnicalSkills}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {requiredSoftSkills.length > 0 && (
          <Row>
            <Col>
              <Row>
                <Col className="section-title">
                  {t("jobs:job_soft_skills_label")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <SoftSkillMatchSection allSkills={requiredSoftSkills} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>{HtmlUtils.htmlToComponents(job.description)}</Col>
        </Row>
        <Row>
          <Col>
            <Row className="approval-action-row">
              <Col className="approval-col">
                {approval.candidateApprovalStatus !==
                  CandidateApprovalStatusEnum.APPROVED && (
                  <ActionButton
                    className="become-spotter-action-button"
                    onClick={() =>
                      onClickApproval()
                    }
                  >
                    {t("jobs:job_approval_action")}
                  </ActionButton>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
