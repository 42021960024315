import React from "react";
import Dropzone from "react-dropzone";
import Image from "react-bootstrap/Image";
import ResumeImage from "../../../img/resume-download.png";
import CorrectImage from "../../../img/correct.png";
import xbytes from "xbytes";
import "./useResumeDropbox.scss";
import useTranslationCandidates from "../useTranslationCandidates";
import WaitForData from "../../common/data-fetching/WaitForData";
import useQueryMaxUploadFileSize from "../../common/file-upload/useQueryMaxUploadFileSize";
import useMutationCandidateUploadResume from "./useMutationCandidateUploadResume";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServerError from "../../common/data-fetching/ServerError";

export default function useResumeDropbox(candidate, className) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const {
    mutate,
    loading,
    errors: mutationErrors,
  } = useMutationCandidateUploadResume();

  const {
    data: maxUploadFileSize,
    loading: loadingMaxUploadFileSize,
    errors: errorsMaxUploadFileSize,
  } = useQueryMaxUploadFileSize();

  // Set the component error message when an error occurs while performing the mutation
  React.useEffect(() => {
    setErrors(mutationErrors);
  }, [mutationErrors]);

  const fileCheckAsPromise = React.useCallback(files => {
    // Display error if more than one file is dragged
    if (files.length > 1) {
      return Promise.reject(
        new ServerError("MultipleFilesSelectedError", t("candidates:candidates_resume_multiple_files_error"))
      );
    }
    // Display error if file size is too big
    if (files[0].size > maxUploadFileSize)
      return Promise.reject(
        new ServerError("MaxFileSizeError", t("candidates:candidates_resume_file_size_error"))
      );

    return Promise.resolve(null);
  }, [t, maxUploadFileSize]);

  const uploadFile = React.useCallback(file => {
    return mutate({
      variables: {
        candidateId: candidate._id,
        file: file,
      },
    });
  }, [mutate, candidate._id]);

  const onDrop = React.useCallback(files => {
    // If we are processing another file, do nothing
    if (loading) return;

    // Clear error from last upload
    setErrors(null);

    // Return nothing if, for some reason, there are no files
    if (!files || files.length === 0 || files[0] === null) return;

    fileCheckAsPromise(files)
      .then(() => uploadFile(files[0]))
      .then((resume) => {
        if (!resume) {
          throw new ServerError("FileUploadError", t("candidates:candidates_details_file_upload_error"));
        } else {
          setUploadSuccess(true);
        }
      })
      .catch((error) => {
        setErrors([error]);
      });
  }, [loading, uploadFile, t, fileCheckAsPromise]);

  const ResumeDropbox = React.useMemo(() =>
    <div
      className={
        "ResumeDropbox " +
        (className ? className : "") +
        " " +
        (loading ? "disabled" : "")
      }
    >
      <WaitForData
        loading={loadingMaxUploadFileSize}
        errors={errorsMaxUploadFileSize}
        onLoaded={() => (
          <Dropzone onDrop={(files) => onDrop(files)}>
            {({ getRootProps, getInputProps }) => (
              <div className="dropZoneContainer" {...getRootProps()}>
                <div className="drop-zone">
                  <div className={"drop-zone-content"}>
                    {loading &&
                      <p>{t("candidates:candidates_resume_processing")}</p>
                    }
                    {!loading &&
                      <>
                        <input {...getInputProps()} />
                        <>
                          {uploadSuccess ?
                            <div className={"success-div"}>
                              <Row>
                                <Col>
                                  <Image src={CorrectImage} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  {t("candidates:candidates_resume_dropbox_success_msg")}
                                </Col>
                              </Row>
                            </div>
                            :
                            <Row>
                              <Col>
                                <Image src={ResumeImage} />
                              </Col>
                            </Row>
                          }
                          <div className="dropbox-placeholder-div">
                            <Row>
                              <Col>
                                <div className="desktop-only">
                                  <Row className="dropbox-placeholder-title">
                                    <Col>
                                      {candidate.resumeId ?
                                        t("candidates:candidates_resume_dropbox_placeholder_update")
                                        :
                                        t("candidates:candidates_resume_dropbox_placeholder_add")
                                      }
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      {t("candidates:candidates_resume_dropbox_placeholder_drag")}
                                    </Col>
                                  </Row>
                                </div>
                                <div className= "mobile-only">
                                  <Row>
                                    <Col>
                                      {candidate.resumeId ?
                                        t("candidates:candidates_resume_dropbox_placeholder_mobile_update")
                                        :
                                        t("candidates:candidates_resume_dropbox_placeholder_mobile_add")
                                      }
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row className="extension-placeholder">
                              <Col>
                                {t("candidates:candidates_resume_dropbox_placeholder_extension")}
                              </Col>
                            </Row>
                            <Row className="max-size-placeholder">
                              <Col>
                                {t("candidates:candidates_resume_dropbox_placeholder_max_size",
                                  {
                                    max: xbytes(maxUploadFileSize, {
                                      space: false,
                                    }),
                                  }
                                )}
                              </Col>
                            </Row>
                          </div>
                        </>
                      </>
                    }
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        )}
      />
    </div>,
    [className, candidate.resumeId, loadingMaxUploadFileSize, errorsMaxUploadFileSize, loading, t,
    maxUploadFileSize, onDrop, uploadSuccess]
  );

  return {
    ResumeDropbox,
    ready: !tLoading,
    uploading: loading,
    errors: errors,
  };
}
