import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./DoughnutChart.scss";

const DoughnutChart = (props) => {
  const { data } = props;

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (item) =>
            ` ${item.label}: ${Number.parseFloat(item.formattedValue).toFixed(
              2
            )}%`,
        },
      },
    },
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <div className="doughnut-chart-container">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
