import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "emailSubscriptionPublic";
const QUERY_NAME = "emailSubscriptionUpdate";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $email: String!,
    $canBeRecommended: Boolean,
    $canReceiveAlert: Boolean
  ) {
    ${QUERY_SCOPE} {
    _id
      ${QUERY_NAME} (
        email: $email,
        canBeRecommended: $canBeRecommended,
        canReceiveAlert: $canReceiveAlert
      ) {
        _id
        email
        canBeRecommended
        canReceiveAlert
      }
    }
  }
`;

export default function useMutationEmailSubscriptionUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
