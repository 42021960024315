import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import EnumFilter from "./EnumFilter";
import EmploymentTypeEnum, {getEmploymentTypeLabel} from "../EmploymentTypeEnum";

export default function EmploymentTypeFilter(props) {
  const { updateSelectedValue, selectedValue, name } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading);

  // Retrieve translations for employment types options (anything before tLoading is ready won't be actually displayed)
  const employmentTypeChoices = React.useMemo(() => {
      if (tLoading)
        return [];

      // Concatenate empty value with all choices from enum
      return ([{
        value: "",
        label: t("jobs:employment_type_all_label")
      }, ...Object.values(EmploymentTypeEnum).map(employmentType => ({
        value: employmentType,
        label: getEmploymentTypeLabel(t, employmentType)
      }))]);
    },
    [t, tLoading]
  );

  // RENDER
  return (
    <EnumFilter
      name={name}
      selectedValue={selectedValue}
      updateSelectedValue={updateSelectedValue}
      choices={employmentTypeChoices}
      label={t("jobs:employment_type_filter_label")}
    />
  );
}
