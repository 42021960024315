import React from "react";
import Page from "../../common/page/Page";
import useTranslationCandidates from "../useTranslationCandidates";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useParams } from "react-router";
import WaitForData from "../../common/data-fetching/WaitForData";
import CandidateCreateForm from "./CandidateCreateForm";
import CandidateUpdateForm from "./CandidateUpdateForm";
import useQueryJobById from "../view/useQueryJobById";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ScrollUtils from "../../common/page/ScrollUtils";
import JobStatusEnum from "../../jobs/JobStatusEnum";

export default function CandidateRecommendPage(/*props*/) {
  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobId = urlParams.jobId;

  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading);
  const { profile } = useAuthUser();
  const location = useLocation();
  const history = useHistory();

  // Current step
  const step = queryString.parse(location.search).step;

  // candidateId, if present (if not present, it's'to create a candidate, otherwise it's to update the candidate)
  const candidateId = queryString.parse(location.search).candidateId;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // We pass candidateId as well, because it changes when a candidate is created
  const changeStep = (step, candidateId) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ step, candidateId }),
    });
    ScrollUtils.scrollToTop();
  };

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={jobLoading}
          errors={jobErrors}
          onLoaded={() => (
            <>
              {job && job.status === JobStatusEnum.PUBLISHED && (
                <Page className="CandidateRecommendPage">
                  <Page.Title loading={tLoading}>{job.title}</Page.Title>
                  <Page.Content>
                    {candidateId ? (
                      <CandidateUpdateForm
                        recruiterId={profile._id}
                        candidateId={candidateId}
                        jobId={job._id}
                        step={step}
                        onChangeStep={changeStep}
                      />
                    ) : (
                      <CandidateCreateForm
                        recruiterId={profile._id}
                        jobId={job._id}
                        onChangeStep={changeStep}
                      />
                    )}
                  </Page.Content>
                </Page>
              )}
              {(!job || job.status !== JobStatusEnum.PUBLISHED) && (
                <Page className="CandidateRecommendPage">
                  <Page.Title loading={jobLoading}>
                    {t("candidates:candidates_job_details_not_found")}
                  </Page.Title>
                </Page>
              )}
            </>
          )}
        />
      )}
    />
  );
}
