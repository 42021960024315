import { Col, Row, Image } from "react-bootstrap";
import heroImg from "../../../img/insights-landing-hero.svg";
import "./HeroSection.scss";
import GetInsightsPane from "./GetInsightsPane";
import CompareInsightsPane from "./CompareInsightsPane";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";

const HeroSection = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "HeroSection");

  return (
    <div className="HeroSection">
      <Row>
        <Col className="hero-section-col">
          <Row>
            <Col>
              <h1>{t("insights:insights_search_page_tagline_label")}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="label-col">
              {t("insights:insights_get_insights_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <GetInsightsPane />
            </Col>
          </Row>
          <Row>
            <Col className="label-col">
              {t("insights:insights_compare_insights_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <CompareInsightsPane />
            </Col>
          </Row>
        </Col>
        <Col className="hero-section-col">
          <Image src={heroImg} />
        </Col>
      </Row>
    </div>
  );
};

export default HeroSection;
