import { Button } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes";

const PopularSearchButton = (props) => {
  const { keyword } = props;

  const history = useHistory();

  const OnClickPopularSearch = () => {
    history.push({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS,
      search: queryString.stringify({
        keyword: keyword,
      }),
    });
  };

  return (
    <Button onClick={OnClickPopularSearch}>
      <SearchIcon className="search-icon" />
      {keyword}
    </Button>
  );
};

export default PopularSearchButton;
