import { React, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import HtmlUtils from "../../../utils/HtmlUtils";
import party from "../../../img/party.svg";
import Image from "react-bootstrap/Image";
import ActionLink from "../../common/widgets/ActionLink";
import ApprovalDetailsJob from "./ApprovalDetailsJob";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import "./CandidateApprovedApplicationBlock.scss";

export default function CandidateApprovedApplicationBlock(props) {
  const { job, applicationId, approval } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateApprovedApplicationBlock");

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const showJob = () => {
    setShow(!show);
    setScroll(!scroll);
  };

  return (
    <>
      <div className="CandidateApprovedApplicationBlock">
        <Row>
          <Col>
            <p className="congratulation-paragraph">
              {t("jobs:job_congratulations")}
            </p>
          </Col>
        </Row>
        <Row className="banner-row">
          <Col className="job-approval-col">
            <div className="job-approval-div">
              <Row>
                <Col>
                  <Row>
                    <Col className="job-approval-text">
                      {HtmlUtils.htmlToComponents(
                        t("jobs:job_approval_action_text", {
                          name: approval.recruiterName,
                          job: job.title,
                        })
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {HtmlUtils.htmlToComponents(
                        t("jobs:job_approval_approved_status_text", {
                          name: approval.recruiterName,
                          job: job.title,
                        })
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="show-detail-job-Row-containeur">
                <Col className="show-detail-job-col">
                  <VisibilityOutlinedIcon />
                </Col>
                <Col className="show-detail-job-col">
                  <ActionLink href="#congratulation" onClick={showJob}>
                    {t("jobs:job_show_detail")}
                  </ActionLink>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="banner-image-col">
            <Image src={party} className="banner-image" />
          </Col>
        </Row>
        <Row>
          <Col id="congratulation" className="job-details-div">
            <Row>
              <Col>
                <ApprovalDetailsJob
                  job={job}
                  applicationId={applicationId}
                  approval={approval}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
