import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouterUtils from "../../../utils/RouterUtils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import "./CandidateSearchWithApplicationCountResult.scss";
import ActionButton from "../../common/widgets/ActionButton";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import DateUtils from "../../../utils/DateUtils";
import ApplicationCountItem from "./ApplicationCountItem";

export default function CandidateSearchWithApplicationCountResult(props) {
  const { item: candidate } = props;

  const history = useHistory();
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateSearchWithApplicationCountResult");

  function onClickCandidate(event, candidateId) {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
        candidateId,
      })
    );
  }

  return (
    <ActionButton
      as={"div"}
      variant="outline-primary"
      onClick={(event) => onClickCandidate(event, candidate._id)}
      className={"CandidateSearchWithApplicationCountResult"}
    >
      <Row className="row-container">
        <Col className={"name-col"}>{candidate.name}</Col>
        <Col className="application-count-col">
          <ApplicationCountItem candidateId={candidate._id} />
        </Col>
        <Col className="date-col">
          {t("candidates:candidates_details_creation_period_days", {
            count: DateUtils.dateDiff(Date.parse(candidate.updatedAt)),
          })}
        </Col>
      </Row>
    </ActionButton>
  );
}
