import React, { useState } from "react";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useQueryCandidateSearch, {
  CandidateSortEnum,
} from "./useQueryCandidateSearch";
import SearchBox from "../../common/components/SearchBox";

const LIST_LENGTH = 10;

export default function CandidateSearchBox(props) {
  const { recruiterId, onSelectOption } = props;
  const [searchValue, setSearchValue] = useState("");

  const {
    data: results,
    loading: candidatesLoading,
    errors: candidatesErrors,
  } = useQueryCandidateSearch(
    0,
    LIST_LENGTH,
    { recruiterId, name: searchValue },
    CandidateSortEnum.UPDATED_AT_DESC
  );

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateSearchBox");

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: candidatesLoading,
      errors: candidatesErrors,
    };
  };

  const errorMsg = t("candidates:candidates_search_filter_error");
  const emptyMsg = t("candidates:candidates_search_filter_empty");
  const placeholder = t("candidates:candidates_search_filter_placeholder");

  const onSelect = (option) => {
    onSelectOption(option);
  };

  return (
    <SearchBox
      className={"JobSearchBox"}
      useQueryOptions={useQueryOptions}
      onSelectOption={onSelect}
      errorMsg={errorMsg}
      emptyMsg={emptyMsg}
      placeholder={placeholder}
      labelKey={"name"}
      setSearchValue={setSearchValue}
    />
  );
}
