import React from "react";
import Page from "../../common/page/Page";
import { useParams } from "react-router";
import useQueryJobById from "./useQueryJobById";
import useQueryApplicationApprovedById from "./useQueryApplicationApprovedById";
import WaitForData from "../../common/data-fetching/WaitForData";
import CandidateApprovedApplicationBlock from "./CandidateApprovedApplicationBlock";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function CandidateApprovedApplicationPage(props) {
  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobId = urlParams.jobId;
  const applicationId = urlParams.applicationId;

  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  const {
    data: approval,
    loading: approvalLoading,
    errors: approvalErrors,
  } = useQueryApplicationApprovedById(applicationId);

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateApprovedApplicationPage");

  return (
    <WaitForData
      loading={jobLoading || approvalLoading}
      errors={jobErrors || approvalErrors}
      onLoaded={() => (
        <>
          {job && (
            <Page className="CandidateApprovedApplicationPage">
              <Page.Title loading={jobLoading} text={job.title} />
              <Page.Content className="spaced-children">
                <CandidateApprovedApplicationBlock
                  job={job}
                  applicationId={applicationId}
                  approval={approval}
                />
              </Page.Content>
            </Page>
          )}
          {!job && (
            <Page className="CandidateApprovedApplicationPage">
              <Page.Title loading={jobLoading}>
                {t("jobs:job_details_not_found")}
              </Page.Title>
            </Page>
          )}
        </>
      )}
    />
  );
}
