import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationLanding from "../useTranslationLanding";
import BecomeSpotterButton from "./BecomeSpotterButton";
import "./CallToActionSection.scss";

export default function CallToActionSection(props) {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "CallToActionSection");

  // RENDER
  return (
    <Row className="CallToActionSection">
      <Col>
        <Row>
          <Col className="action-title-col">
            {t("landing:landing_last_call_to_action_title")}
          </Col>
        </Row>
        <Row className="action-row">
          <Col>
            <BecomeSpotterButton />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
