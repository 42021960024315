import React from "react";
import useTranslationCandidates from "../useTranslationCandidates";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import CandidateSearchWithApplicationCountResult from "../search/CandidateSearchWithApplicationCountResult";
import useQueryCandidateSearch, {
  CandidateSortEnum,
} from "../search/useQueryCandidateSearch";
import LastViewedList from "../../common/components/LastViewedList";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LIST_LENGTH = 50;

export default function CandidatesWithApplicationCountList(props) {
  const { recruiterId } = props;

  const { data, loading, errors } = useQueryCandidateSearch(
    0,
    LIST_LENGTH,
    { recruiterId },
    CandidateSortEnum.UPDATED_AT_DESC
  );

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesWithApplicationCountList");

  const emptyMsg = t("candidates:candidates_search_empty_msg");
  const getItemId = (candidate) => candidate._id;
  const columnsLabels = [
    t("candidates:candidates_page_name_column_label"),
    t("candidates:candidates_page_application_count_column_label"),
    t("candidates:candidates_page_date_column_label"),
  ];

  return (
    <div className="CandidatesWithApplicationCountList">
      <Row>
        <Col>
          <LastViewedList
            items={data ? data.nodes : []}
            loading={loading}
            errors={errors}
            className={"CandidatesWithApplicationCountList"}
            columnsLabels={columnsLabels}
            emptyMsg={emptyMsg}
            getItemId={getItemId}
            SearchResult={<CandidateSearchWithApplicationCountResult />}
          />
        </Col>
      </Row>
    </div>
  );
}
