import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ActionButton from "../common/widgets/ActionButton";
import "./ContactUsForm.scss";
import ValidatedInputText from "../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm";
import ValidatedInputEmail from "../common/widgets/ValidatedInputEmail";
import useTranslationFooter from "./useTranslationFooter";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import { MAX_LENGTH_CONTACT_US_MESSAGE } from "../../constants/ui";
import ValidatedInputRadio from "../common/widgets/ValidatedInputRadio";
import * as ROUTES from "../../constants/routes";
import useMutationContactUsEmailCreate from "./useMutationContactUsEmailCreate";
import useReferrer from "../infra-no-ui/navigation/useReferrer";
import useFeedbackModal from "../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../common/modals/useServerErrorFormatter";

export default function ContactUsForm() {
  const { t, loading: tLoading } = useTranslationFooter();

  const { redirectToReferrer } = useReferrer(ROUTES.HOME);

  useShowLoader(tLoading);

  const onSubmit = (event) => {
    event.preventDefault();
    launch(send);
  };

  const send = () => {

    const fields = getValues();

    return mutate({
      variables: {
        senderName: fields.name,
        senderPhone: fields.phone,
        senderType: fields.userType,
        senderEmail: fields.email,
        subject: fields.subject,
        text: fields.text,
      },
    });
  };

  const {
    mutate,
    loading: mutationLoading,
    errors: mutationErrors,
  } = useMutationContactUsEmailCreate();

  const errorMsg = useServerErrorFormatter(mutationErrors);

  const successMsg = <p>{t("footer:footer_contactus_success_send_msg")}</p>;

  const { launch, FeedbackModal } = useFeedbackModal(
    successMsg,
    errorMsg,
    redirectToReferrer
  );

  const userTypeChoices = [
    {
      value: "COMPANY",
      label: t("footer:footer_label_radio_employer"),
    },
    {
      value: "SPOTTER",
      label: t("footer:footer_label_radio_recruiter"),
    },

    {
      value: "JOB_SEEKER",
      label: t("footer:footer_label_radio_seeker"),
    },

    {
      value: "OTHER",
      label: t("footer:footer_label_radio_other"),
    },
  ];

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  return (
    <div className="ContactUsForm">
      {FeedbackModal}
      <Row className="contactUs">
        <Col>
          <Form className="formContainer" onSubmit={(event) => onSubmit(event)}>
            <Row>
              <Col>
                <FormGroup controlId="name">
                  <ValidatedInputText
                    name="name"
                    control={control}
                    required={true}
                    className="FormControl"
                    autoComplete="firstName"
                    placeholder={t("footer:footer_label_full_name")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup controlId="phone">
                  <ValidatedInputText
                    name={"phone"}
                    control={control}
                    required={false}
                    className="FormControl"
                    placeholder={t("footer:footer_phone_number")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="input" controlId="email">
                  <ValidatedInputEmail
                    name={"email"}
                    control={control}
                    required={true}
                    className="FormControl"
                    autoComplete="email"
                    placeholder={t("footer:footer_email_label")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="input" controlId="subject">
                  <ValidatedInputText
                    name={"subject"}
                    control={control}
                    required={true}
                    className="FormControl"
                    autoComplete="Subject"
                    placeholder={t("footer:footer_subject_label")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className={"radio"}>
                <ValidatedInputRadio
                  inline
                  name={"userType"}
                  control={control}
                  choices={userTypeChoices}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="input" controlId="text">
                  <ValidatedInputText
                    name={"text"}
                    control={control}
                    as={"textarea"}
                    required
                    maxLength={MAX_LENGTH_CONTACT_US_MESSAGE}
                    placeholder={t("footer:footer_contact_us_message_label")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Row className="FormRow">
                    <ActionButton
                      loading={mutationLoading}
                      className="SpinnerButton"
                      type="submit"
                      disabled={!canSubmit()}
                    >
                      {t("footer:footer_button_send_label")}
                    </ActionButton>
                  </Form.Row>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
