import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";

const BLOG_LOCALE = {
  en: "en-US",
  fr: "fr",
};
class BlogUtils {
  static getBlogLocaleCode = (language) => {
    return BLOG_LOCALE[language];
  };

  static getBlogCategoryRoute = (category) => {
    return RouterUtils.injectParamsInRoute(ROUTES.BLOG_CATEGORY, {
      category: category,
    });
  };

  static getBlogPostRoute = (category, url) => {
    return RouterUtils.injectParamsInRoute(ROUTES.BLOG_POST, {
      category: category,
      title: url,
    });
  };
}

export default BlogUtils;
