import { useQuery } from "@apollo/client";
import QueryUtils from "./QueryUtils";
import useGraphQLErrorFormatter from "./useGraphQLErrorFormatter";

/**
 * Calls useQuery with the given query and options. Return the data contents
 * instead of the data attribute (remove unnecessary levels).
 * @param query GQL query
 * @param queryScope Query scope (first child of Query)
 * @param queryName Query name (child of scope)
 * @param options Options, if any, to pass to useQuery
 * @return {{subscribeToMore<TSubscriptionData=any, TSubscriptionVariables=OperationVariables>(options: SubscribeToMoreOptions<any, TSubscriptionVariables, TSubscriptionData>): () => void, variables: OperationVariables | undefined, data: (any | null), called: true, startPolling(pollInterval: number): void, networkStatus: NetworkStatus, refetch(variables?: Partial<OperationVariables>): Promise<ApolloQueryResult<any>>, loading: (boolean | boolean), error: ApolloError, updateQuery<TVars=OperationVariables>(mapFn: (previousQueryResult: any, options: Pick<WatchQueryOptions<TVars, any>, "variables">) => any): void, fetchMore: (<K extends keyof OperationVariables>(fetchMoreOptions: (FetchMoreQueryOptions<OperationVariables, K, any> & FetchMoreOptions<any, OperationVariables>)) => Promise<ApolloQueryResult<any>>) & (<TData2, TVariables2, K extends keyof TVariables2>(fetchMoreOptions: ({query?: DocumentNode | TypedDocumentNode<any, OperationVariables>} & FetchMoreQueryOptions<TVariables2, K, any> & FetchMoreOptions<TData2, TVariables2>)) => Promise<ApolloQueryResult<TData2>>), ...}}
 */

export default function useWrappedQuery(
  query,
  queryScope,
  queryName,
  options = {}
) {
  const { data, loading, error, ...status } = useQuery(query, options);

  // When fetch policy is "cache-and-network", loading is on even if we have data returned from cache.
  // We want loading to be off if we already have data. Beware that data could be partial.
  const overrideLoading =
    data != null && options.fetchPolicy === "cache-and-network"
      ? false
      : loading;

  // Convert the ApolloError into a normalized error
  const normalizedErrors = useGraphQLErrorFormatter(error);

  // Return data directly (without its first GraphQL parent) and return other status info separately
  return {
    data: QueryUtils.removeQueryScopeAndName(data, queryScope, queryName),
    loading: overrideLoading,
    errors: normalizedErrors,
    ...status,
  };
}
