import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import useTranslationBlog from "../useTranslationBlog";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./PaginationSection.scss";

export default function PaginationSection(props) {
  const { url, active, size } = props;

  const { t, loading: tLoading } = useTranslationBlog();
  useShowLoader(tLoading, "BlogBlock");

  const history = useHistory();
  const changePage = (number) => {
    history.push({
      pathname: url,
      search: queryString.stringify({
        page: number,
      }),
    });
    history.go(0);
  };

  return (
    <Pagination className="PaginationSection">
      {active > 1 && (
        <Pagination.Item onClick={() => changePage(1)}>
          {t("blog:blog_page_first")}
        </Pagination.Item>
      )}
      {active > 1 && (
        <Pagination.Item onClick={() => changePage(active - 1)}>
          {t("blog:blog_page_previous")}
        </Pagination.Item>
      )}
      {active < size && (
        <Pagination.Item onClick={() => changePage(active + 1)}>
          {t("blog:blog_page_next")}
        </Pagination.Item>
      )}
      {active < size && (
        <Pagination.Item onClick={() => changePage(size)}>
          {t("blog:blog_page_last")}
        </Pagination.Item>
      )}
    </Pagination>
  );
}
