import React from "react";
import {InputGroup} from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import TypeUtils from "../../../utils/TypeUtils";
import {Typeahead} from "react-bootstrap-typeahead";
import "./SearchBox.scss";

export default function SearchBox(props) {

  const {className, useQueryOptions, onSelectOption, errorMsg, emptyMsg, placeholder, labelKey, setSearchValue} = props;

  const {options: rawOptions, loading: optionsLoading, error: optionsError} = useQueryOptions();

  const onChangeSelection = (options) => {
    if (!TypeUtils.arrayIsEmpty(options))
      onSelectOption(options[0]);
  }

  // Don't prevent the search box from rendering even if we are still loading the options or if an error occurs,
  // simply display no options until they come in
  const options = optionsLoading || optionsError ? [] : rawOptions;

  // Use the typeahead empty label prop to display an error message
  const emptyLabel = optionsError ? errorMsg : emptyMsg;
  const setValue = input => {
    if (setSearchValue)
      setSearchValue(input);
  }

  return (
    <div className={["SearchBox", className].join(" ")}>
      <InputGroup>
        <Typeahead
          id="searchInput"
          onChange={selected => onChangeSelection(selected)}
          placeholder={placeholder}
          labelKey={labelKey}
          options={options}
          emptyLabel={emptyLabel}
          highlightOnlyResult
          paginate={false}
          className={"search-input"}
          onInputChange={setValue}
        />
        <InputGroup.Append className={"search-icon"}>
          <InputGroup.Text><SearchIcon /></InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};
