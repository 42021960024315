import React from "react";
import Page from "../common/page/Page";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "../../img/maw-mouse.png";
import ceo from "../../img/ceo-profile.png";
import cto from "../../img/cto-profile.png";
import cnrc from "../../img/cnrc-logo.png";
import economie from "../../img/economie.png";
import pme from "../../img/pme-logo.png";
import aidirector from "../../img/aidirector-profile.png";
import useTranslationFooter from "./useTranslationFooter";
import ActionButton from "../common/widgets/ActionButton";
import HtmlUtils from "../../utils/HtmlUtils";
import "./AboutUsPage.scss";
import { CALENDLY_URL } from "../../constants/routes";
import RouterUtils from "../../utils/RouterUtils";

export default function AboutUsPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  const onClickDemo = (event) => {
    event.preventDefault();
    RouterUtils.openExternalUrl(CALENDLY_URL);
  };

  return (
    <Page loading={tLoading} className="AboutUsPage">
      <Page.Content className = "page-container">
          <Row>
            <Col className="title-col">{t("footer:footer_about_us_title")}</Col>
          </Row>
          <Row className="story-row">
            <Col>
              {HtmlUtils.htmlToComponents(t("footer:footer_about_us_text"))}
            </Col>
          </Row>
          <Row>
            <Col className="title-col">
              {t("footer:footer_about_us_team_title")}
            </Col>
          </Row>
          <Row>
            <Col className="team-picture-col">
              <Image src={ceo} className="team-picture" />
            </Col>
          </Row>
          <Row>
            <Col className="team-text-col">
              {HtmlUtils.htmlToComponents(
                t("footer:footer_about_us_team_ceo_text")
              )}
            </Col>
          </Row>
          <Row>
            <Col className="team-picture-col">
              <Image src={cto} className="team-picture" />
            </Col>
          </Row>
          <Row>
            <Col className="team-text-col">
              {HtmlUtils.htmlToComponents(
                t("footer:footer_about_us_team_cto_text")
              )}
            </Col>
          </Row>
          <Row>
            <Col className="team-picture-col">
              <Image src={aidirector} className="team-picture" />
            </Col>
          </Row>
          <Row>
            <Col className="team-text-col">
              {HtmlUtils.htmlToComponents(
                t("footer:footer_about_us_team_aidirector_text")
              )}
            </Col>
          </Row>
          <Row>
            <Col className="title-col">{t("footer:footer-partner-title")}</Col>
          </Row>
          <Row className="partner-logo-row">
            <Col className="cnrc-col">
              <a target="blank" href={t("footer:footer_cnrc_url")}>
                <Image className="img-cnrc" src={cnrc} fluid />
              </a>
            </Col>
            <Col className="economi-col">
              <a target="blank" href={t("footer:footer_mei_url")}>
                <Image className="img-partner" src={economie} fluid />
              </a>
            </Col>
            <Col className="pme-col">
              <a target="blank" href={t("footer:footer_pme_url")}>
                <Image className="img-pme" src={pme} fluid />
              </a>
            </Col>
          </Row>
        <Row>
          <Col className="book-demo-row">
            <Row className="logo-row">
              <Col>
                <Image src={logo} fluid />
              </Col>
            </Row>
            <Row>
              <Col className="book-demo-col">
                {t("footer:footer_book_demo_title")}
              </Col>
            </Row>
            <Row className="action-row">
              <Col>
                <ActionButton onClick={onClickDemo} className="action-button">
                  {t("footer:footer_book_demo_button_label")}
                </ActionButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
}
