import React from "react";
import ProfileInlineEditInput from "./ProfileInlineEditInput";
import InlineEditValidatedInputWysiwyg from "../../common/widgets/InlineEditValidatedInputWysiwyg";

/**
 * Generic component for all profile editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProfileInlineEditInputWysiwyg(props) {
  return <ProfileInlineEditInput InlineEditInputComponent={InlineEditValidatedInputWysiwyg} {...props}/>
};
