import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import { QUERY as QUERY_CANDIDATES } from "../view/useQueryCandidateByRecruiterId";

const QUERY_SCOPE = "candidate";
const QUERY_NAME = "candidateCreate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $recruiterId: ID!,
        $name: String!
        $phone: String
        $email: String!
        $linkedin: String
        $indeed: String
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          recruiterId: $recruiterId,
          name: $name,
          phone: $phone,
          email: $email,
          linkedin: $linkedin,
          indeed: $indeed,
        ) {
          _id
          resumeId
          name
          phone
          email
          linkedin
          indeed
        }
      }
    }
  `;

export default function useMutationCandidateCreate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_CANDIDATES],
  });
}
