import React from "react";
import Page from "../../common/page/Page";
import useTranslationCandidates from "../useTranslationCandidates";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import CandidateCreateForm from "./CandidateCreateForm";
import CandidateUpdateForm from "./CandidateUpdateForm";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ScrollUtils from "../../common/page/ScrollUtils";

export default function CandidateCreatePage(/*props*/) {
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading);
  const { profile } = useAuthUser();
  const location = useLocation();
  const history = useHistory();

  // Current step
  const step = queryString.parse(location.search).step;

  // candidateId, if present (if not present, it's'to create a candidate, otherwise it's to update the candidate)
  const candidateId = queryString.parse(location.search).candidateId;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // We pass candidateId as well, because it changes when a candidate is created
  const changeStep = (step, candidateId) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ step, candidateId }),
    });
    ScrollUtils.scrollToTop();
  };

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="CandidateCreatePage">
          <Page.Title loading={tLoading}>
            {t("candidates:candidates_create_candidate_title")}
          </Page.Title>
          <Page.Content>
            {candidateId ? (
              <CandidateUpdateForm
                recruiterId={profile._id}
                candidateId={candidateId}
                step={step}
                onChangeStep={changeStep}
              />
            ) : (
              <CandidateCreateForm
                recruiterId={profile._id}
                onChangeStep={changeStep}
              />
            )}
          </Page.Content>
        </Page>
      )}
    />
  );
}
