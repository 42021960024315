import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ActionButton from "../../common/widgets/ActionButton";
import * as ROUTES from "../../../constants/routes";
import FaqSectionEmployers from "./FaqSectionEmployers";
import HowItWorksEmployers from "./HowItWorksEmployers";
import RouterUtils from "../../../utils/RouterUtils";
import jobPostPicEn from "../../../img/jobPostPic.svg";
import jobPostPicFr from "../../../img/jobPostPicFr.svg";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import { Image } from "react-bootstrap";
import "./EmployerLandingBlock.scss";

export default function EmployerLandingBlock(props) {
  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "EmployerLandingBlock");

  const { getCurrentLanguageCode } = useLanguages();

  function onClickEmployerSignUp(event) {
    event.preventDefault();
    RouterUtils.openExternalUrl(ROUTES.EMPLOYER_PORTAL);
  }

  return (
    <div className="EmployerLandingBlock">
      <Row>
        <Col>
          <div className="banner-div">
            <Row className="banner-row-container">
              <Col className="banner-col">
                <Row className="tagline-text-col">
                  <Col>
                    <h1>{t("employer-landing:landing_tagline_text")}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionButton
                      className="action-button"
                      onClick={(event) => onClickEmployerSignUp(event)}
                    >
                      {t("employer-landing:landing_action_button_label")}
                    </ActionButton>
                  </Col>
                </Row>
              </Col>
              <Col className="image-col">
                <Image
                  src={
                    getCurrentLanguageCode() === "en"
                      ? jobPostPicEn
                      : jobPostPicFr
                  }
                  className="job-image"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <HowItWorksEmployers />
        </Col>
      </Row>
      <Row>
        <Col>
          <FaqSectionEmployers />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="tagline-div">
            <Row>
              <Col className="title-col">
                {t("employer-landing:landing_last_call_to_action_title")}
              </Col>
            </Row>
            <Row className="action-row">
              <Col>
                <ActionButton
                  className="action-button"
                  onClick={(event) => onClickEmployerSignUp(event)}
                >
                  {t("employer-landing:landing_action_button_label")}
                </ActionButton>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
