import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import "./InformationDisplay.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Row, Col } from "react-bootstrap";

export default function InformationDisplay(props) {
  const { information, hoverInformation } = props;
  return (
    <span className="InformationDisplay">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{hoverInformation}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <Button {...triggerHandler}>
            <Row>
              <Col className="icon-info-col ">
                <InfoIcon ref={ref} className="icon-info" />
              </Col>
              <Col className="information-col">
                <span>{information}</span>
              </Col>
            </Row>
          </Button>
        )}
      </OverlayTrigger>
    </span>
  );
}
