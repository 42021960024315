import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import SponsoredRecruiterSearchResult from "../search/SponsoredRecruiterSearchResult";
import useQuerySponsoredRecruiterWithHireCountSearch from "../search/useQuerySponsoredRecruiterWithHireCountSearch";
import RecruiterSortEnum from "../RecruiterSortEnum";
import useTranslationSponsorship from "../useTranslationSponsorship";
import LastViewedList from "../../common/components/LastViewedList";

const LIST_LENGTH = 50;

export default function SponsoredRecruiterList(props) {
  const { recruiterId } = props;

  const { data, loading, errors } = useQuerySponsoredRecruiterWithHireCountSearch(
    recruiterId,
    0,
    LIST_LENGTH,
    { },
    RecruiterSortEnum.CREATED_AT_DESC
  );

  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading, "SponsoredRecruiterList");

  const emptyMsg = t("sponsorship:sponsored_search_empty_msg");
  const getItemId = (candidate) => candidate._id;

  return (
    <div className="SponsoredRecruiterList">
      <LastViewedList
        items={data ? data.nodes : []}
        loading={loading}
        errors={errors}
        className={"SponsoredRecruiterList"}
        emptyMsg={emptyMsg}
        getItemId={getItemId}
        SearchResult={<SponsoredRecruiterSearchResult />}
      />
    </div>
  );
}
