import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ActionButton from "../../common/widgets/ActionButton";
import { CALENDLY_URL } from "../../../constants/routes";
import HowItWorksEmployeeReferral from "./HowItWorksEmployeeReferral";
import RouterUtils from "../../../utils/RouterUtils";
import jobPostPicEn from "../../../img/jobPostPic.svg";
import jobPostPicFr from "../../../img/jobPostPicFr.svg";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import { Image } from "react-bootstrap";
import "./EmployeeReferralLandingBlock.scss";

export default function EmployeeReferralLandingBlock(props) {
  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "EmployeeReferralLandingBlock");

  const { getCurrentLanguageCode } = useLanguages();
  const onClickDemo = (event) => {
    event.preventDefault();
    RouterUtils.openExternalUrl(CALENDLY_URL);
  };

  return (
    <div className="EmployeeReferralLandingBlock">
      <Row>
        <Col>
          <div className="banner-div">
            <Row className="banner-row-container">
              <Col className="banner-col">
                <Row className="tagline-text-col">
                  <Col>
                    <h1>
                      {t(
                        "employer-landing:employee_referral_landing_tagline_text"
                      )}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionButton
                      onClick={onClickDemo}
                      className="action-button"
                    >
                      {t(
                        "employer-landing:employee_referral_book_demo_button_label"
                      )}
                    </ActionButton>
                  </Col>
                </Row>
              </Col>
              <Col className="image-col">
                <Image
                  src={
                    getCurrentLanguageCode() === "en"
                      ? jobPostPicEn
                      : jobPostPicFr
                  }
                  className="job-image"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <HowItWorksEmployeeReferral />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="tagline-div">
            <Row>
              <Col className="title-col">
                {t("employer-landing:employee_referral_call_to_action_title")}
              </Col>
            </Row>
            <Row className="action-row">
              <Col>
                <ActionButton onClick={onClickDemo} className="action-button">
                  {t(
                    "employer-landing:employee_referral_book_demo_button_label"
                  )}
                </ActionButton>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
