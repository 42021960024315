import React from "react";
import { SEO_CONFIG } from "../../../constants/seo";
import Helmet from "react-helmet";

export default function CommonSeo(props) {
  const { title, description, url } = props;
  const socialLinks = SEO_CONFIG.socialLinks;
  const cover = SEO_CONFIG.cover;
  const authorTwitterUserNameToShow =
    SEO_CONFIG.socialLinks.defaultAuthorTwitterUserName;

  return (
    <Helmet>
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        data-react-helmet="true"
      />
      <title>{title}</title>
      <meta name="description" content={description} data-react-helmet="true" />
      <link href={"https://" + url} rel="canonical" data-react-helmet="true" />
      <link href="https://spotterful.com" rel="home" />
      <meta
        property="og:site_name"
        content={SEO_CONFIG.siteName}
        data-react-helmet="true"
      />
      <meta property="og:locale" content="en_CA" data-react-helmet="true" />
      {/* Opengraph meta tags for Facebook & LinkedIn */}
      <meta property="og:url" content={url} data-react-helmet="true" />
      <meta property="og:type" content="website" data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta
        property="og:description"
        content={description}
        data-react-helmet="true"
      />
      <meta property="og:image:alt" content={title} />
      <meta
        property="fb:app_id"
        content={socialLinks.facebookAppId}
        data-react-helmet="true"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        data-react-helmet="true"
      />
      <meta
        name="twitter:creator"
        content={authorTwitterUserNameToShow}
        data-react-helmet="true"
      />
      <meta
        name="twitter:site"
        content={SEO_CONFIG.domain}
        data-react-helmet="true"
      />
      <meta name="twitter:title" content={title} data-react-helmet="true" />
      <meta
        name="twitter:description"
        content={description}
        data-react-helmet="true"
      />
      <meta name="twitter:image" content={cover} data-react-helmet="true" />
    </Helmet>
  );
}
