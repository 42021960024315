import React from "react";
import "./JobSearchItem.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StringUtils from "../../../utils/StringUtils";
import PlaceIcon from "@material-ui/icons/Place";
import BusinessIcon from "@material-ui/icons/Business";
import WorkModeIcon from "@material-ui/icons/HomeWork";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import {useHistory, useParams} from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import {
  formatRequiredTechnicalSkills,
  TechnicalSkillMatchSection,
} from "../resume-matching/TechnicalSkillMatchSection";
import {
  formatRequiredSoftSkills,
  SoftSkillMatchSection,
} from "../resume-matching/SoftSkillMatchSection";
import ActionLink from "../../common/widgets/ActionLink";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {getJobWorkModeLabel} from "../JobWorkModeEnum";
import TypeUtils from "../../../utils/TypeUtils";

export default function JobSearchItem(props) {
  const history = useHistory();

  // Fetch company name from ID provided in the url params.
  const urlParams = useParams();
  const companyName = urlParams.companyName;

  const onClickRecommend = () => {
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_RECOMMEND, {
        jobId: job._id,
      })
    );
  };

  const onClickViewAll = () => {
    if (isInternal) {
      history.push(
        RouterUtils.injectParamsInRoute(ROUTES.INTERNAL_JOB, {
          jobId: job._id,
          companyName
        })
      );
    }
    else {
      history.push(
        RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_JOB, {
          jobId: job._id,
        })
      );
    }
  };

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchItem");

  const { item: job, isInternal } = props;

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  const requiredTechnicalSkills = formatRequiredTechnicalSkills(
    job.technicalSkills
  );
  const requiredSoftSkills = formatRequiredSoftSkills(job.softSkills);

  // RENDER

  return (
    <Card className="JobSearchItem" id={job._id}>
      <Card.Body className="card-container">
        <Row className="card-row-container">
          <Col className="companyInformation">
            <Row>
              <Col className="title-col">
                <ActionLink onClick={onClickViewAll} className="name-col">
                  {job.title}
                </ActionLink>
              </Col>
            </Row>
            <Row>
              <Col className="iconContainer">
                <Row>
                  <Col className="icon-col">
                    <BusinessIcon />
                  </Col>
                  <Col>{job.organization && job.organization.company}</Col>
                </Row>
                {!StringUtils.isNullOrEmpty(addressLine) && (
                  <Row>
                    <Col className="icon-col">
                      <PlaceIcon />
                    </Col>
                    <Col>{addressLine}</Col>
                  </Row>
                )}
                {!StringUtils.isNullOrEmpty(job.workMode) && (
                  <Row>
                    <Col className="icon-col">
                      <WorkModeIcon />
                    </Col>
                    <Col>{getJobWorkModeLabel(t, job.workMode)}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="commission-col">
            <Row>
              <Col className="commission-title-col">
                {t("jobs:job_commission_title")}
              </Col>
            </Row>
            <Row>
              <Col className="commission">
                <span className="commission-span">${job.commission}</span>
              </Col>
            </Row>
            <Row className="list-row">
              <Col>
                <ActionLink onClick={onClickRecommend}>
                  {t("jobs:job_recommend_action")}
                </ActionLink>
              </Col>
            </Row>
          </Col>
        </Row>

        {requiredTechnicalSkills.length > 0 && (
          <>
            <hr />
            <Row>
              <Col className="section-title">
                {t("jobs:job_technical_skills_label")}
              </Col>
            </Row>
            <Row>
              <Col>
                <TechnicalSkillMatchSection
                  allSkills={requiredTechnicalSkills}
                />
              </Col>
            </Row>
          </>
        )}
        {requiredSoftSkills.length > 0 && (
          <>
            <Row>
              <Col className="section-title">
                {t("jobs:job_soft_skills_label")}
              </Col>
            </Row>
            <Row>
              <Col>
                <SoftSkillMatchSection allSkills={requiredSoftSkills} />
              </Col>
            </Row>
          </>
        )}
        <hr />
        <Row className="list-row">
          <Col>
            <ActionLink onClick={onClickViewAll}>
              {t("jobs:job_view_full_description_link")}
            </ActionLink>
            <ArrowForwardIcon />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
