import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import useMutationApplicationCreate from "./useMutationApplicationCreate";
import "./useCandidateSaveApplicationForm.scss";
import ValidatedInputWysiwyg from "../../common/widgets/ValidatedInputWysiwyg";
import {
  formatWysiwygInput,
  formatWysiwygOutput,
  getWysiwygContentLength,
} from "../../common/widgets/WysiwygEditor";
import { MAX_LENGTH_APPLICATION_NOTE } from "../../../constants/ui";
import WysiwygCounterDisplay from "../../common/widgets/WysiwygCounterDisplay";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import useQueryApplicationValidate from "../view/useQueryApplicationValidate";
import WaitForData from "../../common/data-fetching/WaitForData";
import LoadingError from "../../common/loading-widgets/LoadingError";

export default function useCandidateSaveApplicationForm(
  candidate,
  recruiterId,
  jobId
) {
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading);
  const {
    data: isValidCandidate,
    loading: validCandidateLoading,
    errors: validCandidateErrors,
  } = useQueryApplicationValidate(candidate._id, jobId, recruiterId);

  // Return error message
  const getErrorMessage = (code) => {
    switch (code) {
      case "CandidateLockupPeriodError":
        return t(
          "candidates:candidates_server_candidate_lockup_period_error_message"
        );
      case "CandidateMaxActiveApplicationsError":
        return t(
          "candidates:candidates_server_candidate_max_active_applications_error_message"
        );
      case "CandidateCannotBeRecommendedError":
        return t(
          "candidates:candidates_server_candidate_cannot_be_recommended_error_message"
        );
      case "CandidateAlreadyRecommendedError":
        return t(
          "candidates:candidates_server_candidate_already_recommended_error_message"
        );
      case "RecruiterMaxRecommendationsError":
        return t(
          "candidates:candidates_server_recruiter_max_recommendations_error_message"
        );
      default:
        return t("candidates:candidates_server_error_message");
    }
  };

  const serverErrorMessage = validCandidateErrors
    ? getErrorMessage(validCandidateErrors[0].name)
    : null;

  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationApplicationCreate();

  const submit = () => {

    return creationMutate({
      variables: {
        candidateId: candidate._id,
        jobId: jobId,
        recruiterId: recruiterId,
        resumeId: candidate.resumeId,
        recruiterNote: formatWysiwygOutput(note),
      },
    });
  };

  const {
    control,
    formState: { isValid },
    watch,
  } = useValidatedInputForm();

  // Get number of characters typed in the wysiwyg (not counting hidden html tags)
  const note = watch("note");
  const textLength = getWysiwygContentLength(note);

  const canSubmit = () => isValid && isValidCandidate;

  const form = (
    <WaitForData
      loading={validCandidateLoading}
      errors={validCandidateErrors}
      onError={() => <LoadingError message={serverErrorMessage} />}
      onLoaded={() => (
        <div className="CandidateSaveApplicationForm widget-block">
          <Row>
            <Col className={"field-container"}>
              <Row>
                <Col className={"invite"}>{t("candidates:candidates_save_application_invite_msg")}</Col>
              </Row>
              <Row>
                <Col>
                  <ValidatedInputWysiwyg
                    name={"note"}
                    control={control}
                    defaultValue={formatWysiwygInput("")}
                    placeholder={t(
                      "candidates:candidates_save_candidate_note_step_placeholder"
                    )}
                    required
                    maxLength={MAX_LENGTH_APPLICATION_NOTE}
                  />
                  <WysiwygCounterDisplay
                    maxLength={MAX_LENGTH_APPLICATION_NOTE}
                    length={textLength}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    />
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading,
    errors: creationErrors,
  };
}
