import React from "react";
import useQueryJobPublicSearch from "./useQueryJobPublicSearch";
import WaitForData from "../../common/data-fetching/WaitForData";
import SimilarJobsItem from "./SimilarJobsItem";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import { Col, Row } from "react-bootstrap";
import "./SimilarJobsResultList.scss";

export default function SimilarJobsResultList(props) {
  const { query, currentJobId } = props;

  const MAX_RESULT_COUNT = 5;

  // Memoize searchFilters to avoid re-rendering of components that use them after every render of this component
  const searchFilters = React.useMemo(() => ({ queryText: query.slice(0, MAX_RESULT_COUNT) }), [query]);

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchItem");

  const {
    loading,
    errors,
    data: jobs,
  } = useQueryJobPublicSearch(MAX_RESULT_COUNT, searchFilters, "DATE_POSTED_DESC");

  const jobSimilar = jobs.filter((item) => item._id !== currentJobId);

  return (
    <div className="SimilarJobsResultList">
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <div className="simular-jobs-block">
            {jobSimilar.length > 0 && (
              <Row>
                <Col className="similar-jobs-title">
                  {t("jobs:job_similar_jobs_label")}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {jobSimilar.map((item, index) => (
                  <SimilarJobsItem key={index} item={item} />
                ))}
              </Col>
            </Row>
          </div>
        )}
      />
    </div>
  );
}
