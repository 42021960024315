import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryJobById from "./useQueryJobById";
import DisplayDate from "../../common/widgets/DisplayDate";
import DisplayApplicationStatus from "../../candidates/view/DisplayApplicationStatus";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PersonIcon from "@material-ui/icons/Person";
import DateRangeIcon from "@material-ui/icons/DateRange";

export default function ApplicationItem(props) {
  const { application } = props;

  const { data: job, loading, errors } = useQueryJobById(application.jobId);

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <Row className="row-container">
            <Col className={"job-col"}>{job.title}</Col>
            <Col className={"name-col"}>
              <Row>
                <Col className="icon-col">
                  <PersonIcon />
                </Col>
                <Col>{application.candidate.name}</Col>
              </Row>
            </Col>
            <Col className={"approval-col"}>
              <DisplayApplicationStatus application={application} job={job} />
            </Col>
            <Col className={"count-day-col"}>
              <Row>
                <Col className="icon-col">
                  <DateRangeIcon />
                </Col>
                <Col>
                  <DisplayDate date={application.createdAt} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      />
    </HorizontalLoader>
  );
}
