import React from "react";
import useLanguages from "../infra-no-ui/translation/useLanguages";

export default function Privacy() {

  const {getCurrentLanguageCode} = useLanguages();

  // Content is not in translation strings because it's too long; it should go in a CMS instead
  switch(getCurrentLanguageCode()) {
    case 'fr' : return getFrenchPrivacyText();
    default: return getEnglishPrivacyText();
  }
}

const CONTACT_EMAIL = process.env.REACT_APP_INFO_EMAIL;

const getFrenchPrivacyText = () => {
  return (
    <div>
      <p>
        Cette page vous informe de nos politiques concernant la collecte, l'utilisation
        et la divulgation des Données Personnelles lorsque vous utilisez notre application
        et les choix que vous avez à propos de ces données.
      </p>
      <p>
        Mouse At Work Group Inc. s'engage à assurer la protection de vos Données Personnelles.
        Mouse At Work Group Inc. collecte, utilise, divulgue et conserve vos Données Personnelles
        conformément au Règlement Général sur la Protection des Données de l'UE (RGPD), à toute
        loi locale sur la protection des données qui s'applique et à toute autre
        législation pertinente.
      </p>
      <p>
        Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le
        Service, vous acceptez la collecte et l'utilisation des informations
        conformément à la présente politique.
      </p>
      <p>
        Sauf définition contraire dans la présente Politique de Confidentialité, les termes utilisés dans cette
        Politique de Confidentialité ont les mêmes significations que dans nos Conditions Générales.
      </p>
      <h2>Définitions</h2>
      <h4>Service</h4>

      <p>
        Le Service désigne le site web https://spotterful.com et l'application mobile Spotterful
        exploités par le Groupe Mouse At Work Inc.
      </p>

      <h4>Données Personnelles</h4>

      <p>
        Les Données Personnelles désignent les données relatives à un individu vivant qui peut être identifié
        à partir de ces données (ou à partir de celles-ci et d'autres informations soit en notre
        possession ou susceptibles d'entrer en notre possession).
      </p>

      <h4>Données d'Utilisation</h4>
      <p>
        Les Données d'Utilisation sont des données collectées automatiquement soit générées par l'utilisation
        du Service ou de l'infrastructure du Service elle-même (par exemple,
        la durée de la visite d'une page).
      </p>
      <h4>Cookies</h4>
      <p>
        Les Cookies sont de petits fichiers stockés sur votre appareil (ordinateur ou appareil mobile).
      </p>
      <h4>Contrôleur de Données</h4>
      <p>
        Un Contrôleur de Données désigne la personne physique ou morale qui (seule ou
        conjointement ou en commun avec d'autres personnes) détermine les fins
        et la manière dont les informations personnelles sont, ou seront, traitées.
      </p>
      <p>
        Aux fins de la présente Politique de Confidentialité, nous sommes un Contrôleur de Données de vos
        Données Personnelles.
      </p>
      <h4>Processeurs de Données (ou Prestataires de Services)</h4>
      <p>
        Un Processeur de Données (ou Prestataire de Services) désigne toute personne physique ou morale
        qui traite les données pour le compte du Contrôleur de Données.
      </p>
      <p>
        Nous pourrions utiliser les services de différents Processeurs de Données dans le but
        de traiter vos données plus efficacement.
      </p>

      <h4>Personne Concernée (ou Utilisateur)</h4>
      <p>
        La Personne Concernée est toute personne physique qui utilise notre Service et est
        le sujet des données personnelles.
      </p>

      <h2>Collecte et utilisation des informations</h2>
      <p>
        Nous collectons plusieurs types d'information à des fins diverses
        pour vous fournir et améliorer notre Service.
      </p>

      <h3>Types de données collectées </h3>

      <h4>Données personnelles</h4>

      <p>
        Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines
        informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou
        vous identifier (« Données Personnelles »). Les informations personnellement identifiables peuvent
        comprendre, sans s'y limiter :
      </p>
      <ul>
        <li>Courriel (adresse e-mail)</li>
        <li>Prénom et nom</li>
        <li>Numéro de téléphone</li>
        <li>Adresse, état, province, code postal/ZIP, ville</li>
        <li>Intitulé du poste</li>
        <li>Contenu d'un curriculum vitae (CV)</li>
        <li>Cookies et Données d'Utilisation</li>
      </ul>
      <p>
        Nous pouvons utiliser vos données personnelles pour vous contacter avec des infolettres, du marketing
        ou du matériel promotionnel et d'autres informations susceptibles de vous intéresser.
        Vous pouvez refuser de recevoir tout ou partie de ces
        communications de notre part en suivant le lien de désinscription ou les
        instructions fournies dans tout courriel que nous envoyons.
      </p>

      <h4>Données d'Utilisation </h4>

      <p>
        Nous pouvons également collecter les informations que votre navigateur envoie chaque fois que vous
        visitez notre Service ou lorsque vous accédez au Service par ou via un appareil mobile
        ("Données d'Utilisation").
      </p>

      <p>
        Ces Données d'Utilisation peuvent inclure des informations telles que l'adresse du protocole Internet
        (par exemple, l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre
        Service que vous visitez, l'heure et la date de votre visite,
        le temps passé sur ces pages, les identifiants uniques des appareils et d'autres
        données de diagnostic.
      </p>

      <p>
        Lorsque vous accédez au Service avec un appareil mobile, ces Données d'Utilisation peuvent
        inclure des informations telles que le type d'appareil mobile que vous utilisez,
        l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre
        système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez,
        des identifiants uniques d'appareil et d'autres données de diagnostic.
      </p>

      <h4>Données de suivi et de Cookies </h4>

      <p>
        Nous utilisons des Cookies et des technologies de suivi similaires pour suivre l'activité
        sur notre Service et nous détenons certaines informations.
      </p>

      <p>
        Les Cookies sont des fichiers avec une petite quantité de données qui peuvent inclure un
        identifiant unique anonyme. Les Cookies sont envoyés à votre navigateur à partir d'un
        site Web et stockés sur votre appareil. D'autres technologies de suivi sont également
        utilisées tels que les témoins, les balises et les scripts pour collecter et suivre les informations
        et pour améliorer et analyser notre Service.
      </p>

      <p>
        Vous pouvez demander à votre navigateur de refuser tous les Cookies ou d'indiquer quand
        un Cookie est envoyé. Cependant, si vous n'acceptez pas les Cookies, vous pourriez
        ne pas être en mesure d'utiliser certaines parties de notre Service.
      </p>

      <p>Exemples de Cookies que nous pourrions utiliser :</p>
      <ul>
        <li>Cookies de session. Nous pourrions utiliser des Cookies de session pour exploiter notre service.</li>
        <li>
          Cookies de préférence. Nous pourrions utiliser des Cookies de préférence pour mémoriser vos
          préférences et divers paramètres.
        </li>
        <li>
          Cookies de sécurité. Nous pourrions utiliser des Cookies de sécurité à des fins de sécurité.
        </li>
      </ul>

      <h4>Utilisation des Données </h4>

      <p>
        Le Groupe Mouse At Work Inc. utilise les données collectées à diverses fins :
      </p>
      <ul>
        <li>Pour fournir et maintenir notre Service</li>
        <li>Pour vous informer des modifications apportées à notre Service</li>
        <li>
          Pour vous permettre de participer aux fonctionnalités interactives de notre Service
          lorsque vous choisissez de le faire
        </li>
        <li>Pour fournir un soutien client</li>
        <li>
          Pour recueillir des analyses ou des informations précieuses afin que nous puissions améliorer notre
          Service
        </li>
        <li>Pour surveiller l'utilisation de notre Service</li>
        <li>Pour détecter, prévenir et résoudre les problèmes techniques</li>
        <li>
          Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur
          d'autres biens, services et événements que nous proposons et qui sont similaires à
          ceux que vous avez déjà achetés ou demandés, sauf si vous
          avez choisi de ne pas recevoir ces informations
        </li>
      </ul>
      <h4>
        Base juridique du traitement des données personnelles dans le cadre du Règlement Général sur
        la Protection des Données (RGPD)
      </h4>

      <p>
        Si vous venez de l'Espace économique européen (EEE), la base juridique du Groupe Mouse At Work Inc.
        pour la collecte et l'utilisation des informations personnelles
        décrite dans cette Politique de Confidentialité dépend des Données Personnelles que nous collectons
        et le contexte spécifique dans lequel nous les collectons.
      </p>

      <p>Le Groupe Mouse At Work Inc. peut traiter vos Données Personnelles pour les raisons suivantes:</p>
      <ul>
        <li>Nous devons exécuter un contrat avec vous</li>
        <li>Vous nous avez donné la permission de le faire</li>
        <li>
          Le traitement est dans notre intérêt légitime et il n'est pas annulé
          par vos droits
        </li>
        <li>À des fins de traitement des paiements</li>
        <li>Pour se conformer à la loi</li>
      </ul>

      <h4>Rétention des données </h4>

      <p>
        Le Groupe Mouse At Work Inc. conservera vos Données Personnelles aussi longtemps que
        cela est nécessaire aux fins énoncées dans la présente Politique de Confidentialité. Nous allons
        conserver et utiliser vos Données Personnelles dans la mesure nécessaire pour nous conformer à
        nos obligations légales (par exemple, si nous sommes tenus de conserver vos
        données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos
        accords juridiques et nos politiques.
      </p>

      <p>
        Le Groupe Mouse At Work Inc. conservera également les Données d'Utilisation pour
        fins d'analyse. Les Données d'Utilisation sont généralement conservées pendant une période
        de temps plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour
        améliorer la fonctionnalité de notre Service, ou nous sommes légalement tenus de
        conserver ces données plus longtemps.
      </p>

      <h4>Transfert de données </h4>

      <p>
        Vos informations, y compris les Données Personnelles, peuvent être transférées à — et
        maintenues sur — des ordinateurs situés en dehors de votre état, province,
        pays ou autre juridiction gouvernementale où les lois sur la protection des données
        peuvent différer de celles de votre juridiction.
      </p>

      <p>
        Si vous êtes situés à l'extérieur du Canada et que vous choisissez de nous fournir des renseignements,
        veuillez noter que nous pouvons transfer les données, y compris les Données Personnelles, au
        Canada et les y traiter.
      </p>

      <p>
        Votre consentement à cette Politique de Confidentialité suivi de votre soumission de telles
        informations représente votre accord pour ce transfert.
      </p>

      <p>
        Le Groupe Mouse At Work Inc. prendra toutes les mesures raisonnablement nécessaires pour
        veiller à ce que vos données soient traitées en toute sécurité et conformément à cette
        Politique de Confidentialité et aucun transfert de vos Données Personnelles n'aura lieu à
        une organisation ou un pays à moins que des contrôles adéquats soient en place
        y compris la sécurité de vos données et autres informations personnelles.
      </p>

      <h4>Divulgation des données</h4>

      <h5>Opération commerciale</h5>

      <p>
        Si le Groupe Mouse At Work Inc. est impliqué dans une fusion, une acquisition ou
        une vente d'actifs, vos Données Personnelles peuvent être transférées. Nous fournirons un
        préavis avant que vos Données Personnelles ne soient transférées et fassent l'objet d'une
        Politique de Confidentialité différente.
      </p>

      <h5>Divulgation aux forces de l'ordre </h5>

      <p>
        Dans certaines circonstances, le Groupe Mouse At Work Inc. peut être tenu de
        divulguer vos Données Personnelles si la loi l'exige ou en réponse
        aux demandes valables des autorités publiques (par exemple, un tribunal ou une
        agence gouvernementale).
      </p>

      <h5>Exigences légales </h5>

      <p>
        Le Groupe Mouse At Work Inc. peut divulguer vos Données Personnelles si nous croyons
        de bonne foi qu'une telle action est nécessaire pour:
      </p>
      <ul>
        <li>Se conformer à une obligation légale</li>
        <li>
          Protéger et défendre les droits ou la propriété du Groupe Mouse At Work Inc.
        </li>
        <li>
          Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le
          Service
        </li>
        <li>
          Protéger la sécurité personnelle des utilisateurs du Service ou du public
        </li>
        <li>Se protéger contre la responsabilité légale</li>
      </ul>

      <h4>Sécurité des données </h4>

      <p>
        La sécurité de vos données est importante pour nous mais n'oubliez pas qu'aucune méthode
        de transmission sur Internet ni méthode de stockage électronique est
        100 % sécurisée. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour
        protéger vos Données Personnelles, nous ne pouvons garantir leur sécurité absolue.
      </p>

      <h4>
        Notre politique sur les "Do Not Track Signals" dans le cadre du California Online
        Protection Act (CalOPPA)
      </h4>

      <p>
        Nous ne prenons pas en charge "Do Not Track" ("DNT"). "Do Not Track" est une préférence que vous
        pouvez définir dans votre navigateur Web pour informer les sites Web que vous ne souhaitez pas
        être suivi.
      </p>

      <p>
        Vous pouvez activer ou désactiver "Do Not Track" en visitant la page des préférences ou
        page de paramètres de votre navigateur Web.
      </p>

      <h4>
        Vos droits en matière de protection des données en vertu du Règlement Général sur la Protection des Données
        (RGPD)
      </h4>

      <p>
        Le Groupe Mouse At Work Inc. vise à prendre des mesures raisonnables pour vous permettre de
        corriger, modifier, supprimer ou limiter l'utilisation de vos Données Personnelles.
      </p>

      <p>
        Si vous souhaitez être informé des Données Personnelles que nous détenons à votre sujet, veuillez nous contacter.
        et si vous souhaitez qu'elles soient supprimées de nos systèmes,
      </p>

      <p>
        Vous pouvez supprimer les Données Personnelles que nous détenons à votre sujet. Pour ce faire, rendez-vous dans
        les paramètres de votre compte et cliquez sur le lien de suppression de compte.
        Si vous avez besoin d'aide, veuillez nous contacter.
      </p>

      <p>
        Dans certaines circonstances, vous disposez des droits de protection des données suivants :
      </p>
      <ul>
        <li>
          Le droit d'accéder, de mettre à jour ou de supprimer les informations que nous avons sur vous.
          Chaque fois que cela est possible, vous pouvez accéder, mettre à jour ou demander la suppression de
          vos Données Personnelles directement dans la section des paramètres de votre compte. Si
          vous ne pouvez pas effectuer ces actions vous-même, veuillez nous contacter pour
          vous aider.
        </li>

        <li>
          Le droit de rectification. Vous avez le droit de faire rectifier
          vos informations si ces informations sont inexactes ou incomplètes.
        </li>

        <li>
          Le droit de s'opposer. Vous avez le droit de vous opposer à notre traitement de
          vos Données Personnelles.
        </li>

        <li>
          Le droit de restriction. Vous avez le droit de demander que nous
          restreignons le traitement de vos informations personnelles.
        </li>

        <li>
          Le droit à la portabilité des données. Vous avez le droit de recevoir
          une copie des informations que nous avons sur vous dans un format structuré,
          lisible par machine et couramment utilisé.
        </li>

        <li>
          Le droit de retirer son consentement. Vous avez également le droit de retirer
          votre consentement à tout moment lorsque le Groupe Mouse At Work Inc. s'est appuyé sur
          votre consentement au traitement de vos informations personnelles.
        </li>
      </ul>

      <p>
        Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de
        répondre à de telles demandes.
      </p>

      <p>
        Vous avez le droit de vous plaindre auprès d'une autorité de protection des données à propos de notre
        collecte et notre utilisation de vos données personnelles. Pour plus d'information, veuillez
        contacter votre autorité locale de protection des données.
      </p>

      <h4>Fournisseurs de services </h4>
      <p>
        Nous pouvons employer des sociétés tierces et des individus pour faciliter notre
        Service ("fournisseurs de services"), fournir le service en notre nom,
        effectuer des services liés au Service ou nous aider à analyser comment notre
        Service est utilisé.
      </p>

      <p>
        Ces tiers n'ont accès à vos Données Personnelles que pour effectuer
        ces tâches en notre nom et sont tenus de ne pas les divulguer ou les utiliser
        à toute autre fin.
      </p>

      <h4>Analytique</h4>
      <p>
        Nous pouvons utiliser des fournisseurs de services tiers pour surveiller et analyser l'utilisation
        de notre Service.
      </p>

      <h6>Google Analytics</h6>
      <p>
        Google Analytics est un service d'analyse Web proposé par Google qui
        suit et rapporte le trafic du site Web. Google utilise les données collectées pour
        suivre et surveiller l'utilisation de notre Service. Ces données sont partagées avec d'autres
        services Google. Google peut utiliser les données collectées pour contextualiser et
        personnaliser les annonces de son propre réseau publicitaire.
      </p>

      <p>
        Pour plus d'informations sur les pratiques de confidentialité de Google, veuillez visiter
        la page <a href="https://policies.google.com/privacy?hl=fr" target="_blank" rel="noreferrer">Règles de confidentialité et conditions d'utilisation</a> de Google.

      </p>

      <h4>Paiements </h4>

      <p>
        Nous pouvons fournir des produits et/ou services payants au sein du Service. Dans ce
        cas, nous utilisons des services tiers pour le traitement des paiements ("processeurs de
        paiement").
      </p>

      <p>
        Nous ne stockerons ni ne collecterons les détails de votre carte de paiement. Ces informations
        sont fournies directement à nos processeurs de paiement tiers dont l'utilisation de
        vos informations personnelles est régie par leur politique de confidentialité. Ces
        processeurs de paiement adhèrent aux normes définies par PCI-DSS telles que gérées par
        le Conseil des normes de sécurité PCI, qui est un effort conjoint des marques
        comme Visa, MasterCard, American Express et Discover. Les exigences de PCI-DSS
        contribuent à garantir le traitement sécurisé des informations de paiement.
      </p>

      <p>Les processeurs de paiement avec lesquels nous travaillons sont:</p>

      <h6>Paiements intégrés à l'Apple Store</h6>

      <p>
        Leur politique de confidentialité peut être consultée <a href="https://www.apple.com/ca/fr/legal/privacy/" target="_blank" rel="noreferrer">ici</a>.
      </p>

      <h6>Paiements via l'application Google Play</h6>

      <p>
        Leur politique de confidentialité peut être consultée <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">ici</a>.
      </p>

      <h6>Stripe</h6>

      <p>
        Leur politique de confidentialité peut être consultée <a href="https://stripe.com/us/privacy" target="_blank" rel="noreferrer">ici</a>.
      </p>

      <h6>PayPal ou Braintree</h6>

      <p>
        Leur politique de confidentialité peut être consultée <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank" rel="noreferrer">ici</a>.
      </p>

      <h6>Square</h6>

      <p>
        Leur politique de confidentialité peut être consultée <a href="https://squareup.com/legal/privacy-no-account" target="_blank" rel="noreferrer">ici</a>.
      </p>

      <h6>Liens vers d'autres sites </h6>

      <p>
        Notre Service peut contenir des liens vers d'autres sites qui ne sont pas exploités par
        nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de cette tierce partie.
      </p>
      <p>
        Nous vous conseillons fortement de consulter la politique de confidentialité de chaque site que vous
        visitez.
      </p>

      <p>
        Nous n'avons aucun contrôle et n'assumons aucune responsabilité pour le contenu,
        politiques ou pratiques de confidentialité de tout site ou service tiers.
      </p>

      <h3>Vie privée des enfants </h3>

      <p>
        Notre Service ne s'adresse à aucune personne de moins de 18 ans ("Enfant").
      </p>

      <p>
        Nous ne collectons pas sciemment des informations personnellement identifiables de
        toute personne de moins de 18 ans. Si vous êtes un parent ou tuteur et que vous
        avez connaissance que votre enfant nous a fourni des Données Personnelles, veuillez nous
        contacter. Si nous apprenons que nous avons collecté des Données Personnelles d'un enfant
        sans vérification du consentement parental, nous prenons des mesures pour
        supprimer ces informations de nos serveurs.
      </p>

      <h3>Modifications apportées à cette Politique de Confidentialité </h3>

      <p>
        Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous informerons
        de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page.
      </p>

      <p>
        Nous vous informerons par courriel et/ou par un avis bien visible sur notre Service,
        avant l'entrée en vigueur du changement, et mettrons à jour la "date d'entrée en vigueur"
        en haut de cette Politique de Confidentialité.
      </p>

      <p>
        Il vous est conseillé de relire périodiquement cette Politique de Confidentialité pour tout
        changement. Les modifications apportées à cette Politique de Confidentialité sont effectives
        lorsqu'elles sont publiées sur cette page.
      </p>

      <h3>Contactez-nous </h3>

      <p>
        Si vous avez des questions concernant cette Politique de Confidentialité, veuillez nous contacter :
      </p>
      <ul>
        <li>Par courriel: <a href={"mailto:" + CONTACT_EMAIL}>{CONTACT_EMAIL}</a></li>
      </ul>
    </div>
  );
}

const getEnglishPrivacyText = () => {
  return (
    <div>
      <p>
        This page informs you of our policies regarding the collection, use and
        disclosure of personal data when you use our application and the choices
        you have associated with that data.
      </p>
      <p>
        Mouse At Work Group Inc. is committed to the protection of your personal data.
        Mouse At Work Group Inc. collects, uses, discloses and retains your personal data
        in accordance with the EU General Data Protection Regulation (GDPR), any
        local data protection laws that apply and all other relevant
        legislation.
      </p>
      <p>
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy.
      </p>
      <p>
        Unless otherwise defined in this Privacy Policy, the terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions.
      </p>
      <h2>Definitions</h2>
      <h4>Service</h4>

      <p>
        Service means the https://spotterful.com website and the Spotterful
        mobile application operated by Mouse At Work Group Inc.
      </p>

      <h4>Personal Data</h4>

      <p>
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </p>

      <h4>Usage Data</h4>
      <p>
        Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).
      </p>
      <h4>Cookies</h4>
      <p>
        Cookies are small files stored on your device (computer or mobile
        device).
      </p>
      <h4>Data Controller</h4>
      <p>
        Data Controller means the natural or legal person who (either alone or
        jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal information are, or are to
        be, processed.
      </p>
      <p>
        For the purpose of this Privacy Policy, we are a Data Controller of your
        Personal Data.
      </p>
      <h4>Data Processors (or Service Providers)</h4>
      <p>
        Data Processor (or Service Provider) means any natural or legal person
        who processes the data on behalf of the Data Controller.
      </p>
      <p>
        We may use the services of various Service Providers in order to process
        your data more effectively.
      </p>

      <h4>Data Subject (or User)</h4>
      <p>
        Data Subject is any living individual who is using our Service and is
        the subject of Personal Data.
      </p>
      <h2>Information Collection and Use</h2>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <h3>Types of Data Collected </h3>

      <h4>Personal Data</h4>

      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
        <li>Job title</li>
        <li>Resume contents</li>
        <li>Cookies and Usage Data</li>
      </ul>
      <p>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or the
        instructions provided in any email we send.
      </p>

      <h4>Usage Data </h4>

      <p>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access the Service by or through a mobile
        device ("Usage Data").
      </p>

      <p>
        This Usage Data may include information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>

      <p>
        When you access the Service with a mobile device, this Usage Data may
        include information such as the type of mobile device you use, your
        mobile device unique ID, the IP address of your mobile device, your
        mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.
      </p>

      <h4>Tracking and Cookies Data </h4>

      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </p>

      <p>
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyse our Service.
      </p>

      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>

      <p>Examples of Cookies we may use:</p>
      <ul>
        <li>Session Cookies. We may use Session Cookies to operate our Service.</li>
        <li>
          Preference Cookies. We may use Preference Cookies to remember your
          preferences and various settings.
        </li>
        <li>
          Security Cookies. We may use Security Cookies for security purposes.
        </li>
      </ul>

      <h4>Use of Data </h4>

      <p>
        Mouse At Work Group Inc. uses the collected data for various purposes:
      </p>
      <ul>
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer support</li>
        <li>
          To gather analysis or valuable information so that we can improve our
          Service
        </li>
        <li>To monitor the usage of our Service</li>
        <li>To detect, prevent and address technical issues</li>
        <li>
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </li>
      </ul>
      <h4>
        Legal Basis for Processing Personal Data under the General Data
        Protection Regulation (GDPR)
      </h4>

      <p>
        If you are from the European Economic Area (EEA), Mouse At Work Group
        Inc. legal basis for collecting and using the personal information
        described in this Privacy Policy depends on the Personal Data we collect
        and the specific context in which we collect it.
      </p>

      <p>Mouse At Work Group Inc. may process your Personal Data because:</p>
      <ul>
        <li>We need to perform a contract with you</li>
        <li>You have given us permission to do so</li>
        <li>
          The processing is in our legitimate interests and it is not overridden
          by your rights
        </li>
        <li>For payment processing purposes</li>
        <li>To comply with the law</li>
      </ul>
      <h4>Retention of Data </h4>

      <p>
        Mouse At Work Group Inc. will retain your Personal Data only for as long
        as is necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes and enforce our
        legal agreements and policies.
      </p>

      <p>
        Mouse At Work Group Inc. will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter period
        of time, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer periods.
      </p>

      <h4>Transfer of Data </h4>

      <p>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </p>

      <p>
        If you are located outside Canada and choose to provide information to
        us, please note that we may transfer the data, including Personal Data, to
        Canada and process it there.
      </p>

      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>

      <p>
        Mouse At Work Group Inc. will take all the steps reasonably necessary to
        ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer of your Personal Data will take place to
        an organisation or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </p>

      <h4>Disclosure of Data</h4>

      <h5>Business Transaction</h5>

      <p>
        If Mouse At Work Group Inc. is involved in a merger, acquisition or
        asset sale, your Personal Data may be transferred. We will provide
        notice before your Personal Data is transferred and becomes subject to a
        different Privacy Policy.
      </p>

      <h5>Disclosure for Law Enforcement </h5>

      <p>
        Under certain circumstances, Mouse At Work Group Inc. may be required to
        disclose your Personal Data if required to do so by law or in response
        to valid requests by public authorities (e.g. a court or a government
        agency).
      </p>

      <h5>Legal Requirements </h5>

      <p>
        Mouse At Work Group Inc. may disclose your Personal Data in the good
        faith belief that such action is necessary to:
      </p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>
          To protect and defend the rights or property of Mouse At Work Group
          Inc.
        </li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>
      <h4>Security of Data </h4>

      <p>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>

      <h4>
        Our Policy on "Do Not Track" Signals under the California Online
        Protection Act (CalOPPA)
      </h4>

      <p>
        We do not support Do Not Track ("DNT"). Do Not Track is a preference you
        can set in your web browser to inform websites that you do not want to
        be tracked.
      </p>

      <p>
        You can enable or disable Do Not Track by visiting the preferences page or
        settings page of your web browser.
      </p>

      <h4>
        Your Data Protection Rights under the General Data Protection Regulation
        (GDPR)
      </h4>

      <p>
        Mouse At Work Group Inc. aims to take reasonable steps to allow you to
        correct, amend, delete or limit the use of your Personal Data.
      </p>

      <p>
        If you wish to be informed about what Personal Data we hold about you, please contact us.
      </p>

      <p>
        You can delete the Personal Data we hold about you. To do so, go to your account settings and click
        the account deletion link. If you need assistance, please contact us.
      </p>

      <p>
        In certain circumstances, you have the following data protection rights:
      </p>
      <ul>
        <li>
          The right to access, update or delete the information we have on you.
          Whenever made possible, you can access, update or request deletion of
          your Personal Data directly within your account settings section. If
          you are unable to perform these actions yourself, please contact us to
          assist you.
        </li>

        <li>
          The right of rectification. You have the right to have your
          information rectified if that information is inaccurate or incomplete.
        </li>

        <li>
          The right to object. You have the right to object to our processing of
          your Personal Data.
        </li>

        <li>
          The right of restriction. You have the right to request that we
          restrict the processing of your personal information.
        </li>

        <li>
          The right to data portability. You have the right to be provided with
          a copy of the information we have on you in a structured,
          machine-readable and commonly used format.
        </li>

        <li>
          The right to withdraw consent. You also have the right to withdraw
          your consent at any time where Mouse At Work Group Inc. relied on
          your consent to process your personal information.
        </li>
      </ul>

      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>

      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority.
      </p>

      <h4>Service Providers </h4>
      <p>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), provide the Service on our behalf,
        perform Service- related services or assist us in analysing how our
        Service is used.
      </p>

      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h4>Analytics</h4>
      <p>
        We may use third-party Service Providers to monitor and analyse the use
        of our Service.
      </p>

      <h6>Google Analytics</h6>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network.
      </p>

      <p>
        For more information on the privacy practices of Google, please visit
        the <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">Google Privacy &amp; Terms web page</a>.
      </p>

      <h4>Payments </h4>

      <p>
        We may provide paid products and/or services within the Service. In that
        case, we use third-party services for payment processing (e.g. payment
        processors).
      </p>

      <p>
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, MasterCard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>

      <p>The payment processors we work with are:</p>

      <h6>Apple Store In-App Payments</h6>

      <p>
        Their Privacy Policy can be viewed <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank" rel="noreferrer">here</a>.
      </p>

      <h6>Google Play In-App Payments</h6>

      <p>
        Their Privacy Policy can be viewed <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">here</a>.
      </p>

      <h6>Stripe</h6>

      <p>
        Their Privacy Policy can be viewed <a href="https://stripe.com/us/privacy" target="_blank" rel="noreferrer">here</a>.
      </p>

      <h6>PayPal or Braintree</h6>

      <p>
        Their Privacy Policy can be viewed <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank" rel="noreferrer">here</a>.
      </p>

      <h6>Square</h6>

      <p>
        Their Privacy Policy can be viewed <a href="https://squareup.com/legal/privacy-no-account" target="_blank" rel="noreferrer">here</a>.
      </p>

      <h6>Links to Other Sites </h6>

      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site.
      </p>
      <p>
        We strongly advise you to review the Privacy Policy of every site you
        visit.
      </p>

      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h3>Children's Privacy </h3>

      <p>
        Our Service does not address anyone under the age of 18 ("Children").
      </p>

      <p>
        We do not knowingly collect personally identifiable information from
        anyoneunder the age of 18. If you are a parent or guardian and you are
        aware that your Child has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>

      <h3>Changes to This Privacy Policy </h3>

      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>

      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h3>Contact Us </h3>

      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>By email: <a href={"mailto:" + CONTACT_EMAIL}>{CONTACT_EMAIL}</a></li>
      </ul>
    </div>
  );
}
