import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationCandidates from "../useTranslationCandidates";
import FormGroup from "react-bootstrap/esm/FormGroup";
import useResumeDropbox from "./useResumeDropbox";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import useMutationCandidateCreate from "./useMutationCandidateCreate";
import useMutationCandidateUpdate from "./useMutationCandidateUpdate";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import indeed from "../../../img/indeed.png";
import Image from "react-bootstrap/esm/Image";
import "./useCandidateSaveResumeForm.scss";

export default function useCandidateSaveResumeForm(candidate) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const {
    ResumeDropbox,
    ready: dropboxReady,
    uploading: dropboxUploading,
    errors: dropboxErrors,
  } = useResumeDropbox(candidate, "dropbox");

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const submit = () => {
    const fields = getValues();
    if (candidate._id) {
      return updateMutate({
        variables: {
          id: candidate._id,
          linkedin: fields.linkedin.trim(),
          indeed: fields.indeed.trim(),
        },
      });
    } else {
      return creationMutate({
        variables: {
          recruiterId: candidate.recruiterId,
          linkedin: fields.linkedin.trim(),
          indeed: fields.indeed.trim(),
        },
      });
    }
  };

  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationCandidateUpdate();
  useShowLoader(tLoading);

  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationCandidateCreate();

  const errors = creationErrors || updateErrors || dropboxErrors;

  const form = (
    <div className="CandidateSaveResumeForm">
      <Row>
        <Col className="profile-choice-col">
          <p className="profile-choice-paragraph">
            {t("candidates:candidates_choice_profile")}
          </p>
        </Col>
      </Row>
      <div className="input-div-container">
        <Row className="input-row-container">
          <Col className="input-col-container">
            <Row className="input-row-container">
              <Col className="icon-col">
                <LinkedInIcon className="linkedIn-icon" />
              </Col>
              <Col className="input-col">
                <FormGroup controlId="linkedin">
                  <ValidatedInputText
                    name="linkedin"
                    className="FormControl"
                    defaultValue={candidate.linkedin}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_linkedin_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="input-row-container">
              <Col className="icon-col">
                <div className="indeed-icon-div">
                  <Image src={indeed} />
                </div>
              </Col>
              <Col className="input-col">
                <FormGroup controlId="indeed">
                  <ValidatedInputText
                    name="indeed"
                    className="FormControl"
                    defaultValue={candidate.indeed}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_indeed_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="download-col-container">
            <Row>
              <Col className="resume-div">
                <div>{ResumeDropbox}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading && dropboxReady,
    submitting: dropboxUploading && (updateLoading || creationLoading),
    errors,
  };
}
