import React from "react";
import StringUtils from "../../../utils/StringUtils";
import SkillMatchBlock from "./SkillMatchBlock";

/**
 * Converts a string to lowercase
 * @param string
 * @return {*}
 */
function normalizeString(string) {
  return string.toLowerCase();
}

function hasOneMatch(text, skill) {
  // We want the skill to match a whole word. \b includes dashes.
  const regexp = new RegExp('\\b' + StringUtils.escapeForRegExp(skill) + '\\b');

  return text.match(regexp) !== null;
}

export default function SkillMatchInText(props) {

  const {allSkills, actualSkillsAsText} = props;

  // Skills to compare must be normalized, but also pass a copy of allSkills as is, to display uppercase letters
  const normalizedText = normalizeString(StringUtils.nullToEmpty(actualSkillsAsText));

  // RENDER

  return (
    <SkillMatchBlock skills={allSkills} matchFunction={item => hasOneMatch(normalizedText, normalizeString(item))}/>
  );
}
