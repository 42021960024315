import React from "react";
import { useController } from "react-hook-form";
import FormControl from "react-bootstrap/FormControl";
import useTranslationCommon from "../useTranslationCommon";
import "./ValidatedInput.scss";
import StringUtils from "../../../utils/StringUtils";
import { MAX_LENGTH_INPUT } from "../../../constants/ui";

export default function ValidatedInputText(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  const {
    name,
    control,
    inputRef,
    defaultValue,
    maxLength = MAX_LENGTH_INPUT,
    min,
    minLength,
    max,
    required,
    placeholder,
    ...otherProps
  } = props;

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    rules: { maxLength, required, min, max, minLength },
    name,
    control,
    defaultValue: StringUtils.nullToEmpty(defaultValue),
  });

  let errorMessage = "";

  if (error && !tLoading) {
    switch (error.type) {
      case "maxLength":
        errorMessage = t("common:common_input_length_max");
        break;

      case "required":
        errorMessage = t("common:common_input_required");
        break;

      case "min":
        errorMessage = t("common:common_input_min_value", { value: min });
        break;

      case "max":
        errorMessage = t("common:common_input_max_value", { value: max });
        break;

      case "minLength":
        errorMessage = t("common:common_input_length_min", {
          count: minLength,
        });
        break;

      default:
        errorMessage = error.type;
    }
  }

  return (
    <div className="ValidatedInput">
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      <FormControl
        className="input"
        placeholder={required ? StringUtils.nullToEmpty(placeholder).concat("*") : placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        ref={inputRef}
        size="lg"
        {...otherProps}
      />
    </div>
  );
}
