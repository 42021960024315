import React from "react";
import TypeUtils from "../../utils/TypeUtils";
import { Redirect, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import UserIsAuthorized from "../authentication-no-ui/UserIsAuthorized";

/**
 * Calls UserIsAuthorized to check that current user can perform props.action, and adds the following behaviour:
 *
 * In case permission is denied because the user is not authenticated, the user is redirected to the Sign In page.
 * In case permission is denied because the user does not have the required level of permissions, a message is displayed
 * instead of redirecting.
 *
 * We use the render prop pattern (displaying a function instead of props.children) because we don't want the component calling
 * WithRedirectionUserIsAuthorized to evaluate the children of WithRedirectionUserIsAuthorized unless the user has access to it.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function WithRedirectionUserIsAuthorized(props) {
  /**
   * Redirects to ROUTES.SIGN_IN if user is not signed in, otherwise display a message stating that user doesn't have
   * the required permissions.
   *
   * We could instead redirect to ROUTES.SIGN_UP, but some apps do not have that page (user creation is done manually
   * in the authentication service).
   *
   * @return {*}
   */
  const onUnauthorized = () => {
    if (!authUser.authenticated) {
      // Referrer is current location without its own referrer state, if any
      const referrer = TypeUtils.shallowCopyObject(location);
      if (referrer.state) delete referrer.state.referrer;
      return (
        <Redirect
          to={{
            pathname: ROUTES.SIGN_IN,
            state: { referrer: referrer },
          }}
        />
      );
    } else return t("authentication-ui:unauthorized_msg");
  };

  const location = useLocation();
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  const authUser = useAuthUser();

  useShowLoader(tLoading, "WithRedirectionUserIsAuthorized");

  return <UserIsAuthorized {...props} no={onUnauthorized} />;
}
