import Page from "../../common/page/Page";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import InsightsResultBlock from "./InsightsResultBlock";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import useQueryInsightCount from "./useQueryInsightCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import InsightsNoResultBlock from "./InsightsNoResultBlock";
import useLanguages from "../../infra-no-ui/translation/useLanguages";

const InsightsResultPage = () => {
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const keyword = searchParams.keyword;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsResultPage");

  // Show insights in the selected language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const {
    loading,
    errors,
    data: insightCount,
  } = useQueryInsightCount(keyword, currentLanguageCode);

  const noResultLabel = t("insights:insights_no_result_label", {
    keyword,
  });

  return (
    <Page className="InsightsResultPage">
      <Page.Content>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <>
              {insightCount > 0 && (
                <InsightsResultBlock
                  keyword={keyword}
                  language={currentLanguageCode}
                  insightCount={insightCount}
                />
              )}

              {!insightCount && <InsightsNoResultBlock label={noResultLabel} />}
            </>
          )}
        />
      </Page.Content>
    </Page>
  );
};

export default InsightsResultPage;
