import useQueryInsightMostImportantResponsibilities from "./useQueryInsightMostImportantResponsibilities";
import WaitForData from "../../common/data-fetching/WaitForData";
import { Collapse } from "react-bootstrap";
import ActionLink from "../../common/widgets/ActionLink";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import "./ResponsibilitiesCard.scss";
import { useState } from "react";
import Loader from "../../common/loading-widgets/Loader";
import SkeletonList from "./SkeletonList";

const TOTAL_RESPONSIBILITIES_LIMIT = 50;
const DEFAULT_LIST_LIMIT = 20;

const ResponsibilitiesCard = (props) => {
  const { keyword, language, title } = props;
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "QuoteSection");

  const [viewMore, setViewMore] = useState(false);

  const {
    loading,
    errors,
    data: responsibilities,
  } = useQueryInsightMostImportantResponsibilities(
    keyword,
    TOTAL_RESPONSIBILITIES_LIMIT,
    language
  );

  return (
    <div className="widget-block">
      <Loader icon={<SkeletonList />}>
        <div className="card-title">{title}</div>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <>
              <ul>
                {responsibilities
                  .slice(0, DEFAULT_LIST_LIMIT)
                  .map((responsibility, index) => (
                    <li key={index}>{responsibility.firstValue}.</li>
                  ))}
              </ul>
              <Collapse in={viewMore}>
                <ul id="show-more-list">
                  {responsibilities
                    .slice(DEFAULT_LIST_LIMIT, TOTAL_RESPONSIBILITIES_LIMIT)
                    .map((responsibility, index) => (
                      <li key={index}>{responsibility.firstValue}.</li>
                    ))}
                </ul>
              </Collapse>
              <ActionLink
                onClick={() => setViewMore(!viewMore)}
                aria-controls="show-more-list"
                aria-expanded={viewMore}
              >
                {viewMore
                  ? t("insights:insights_load_less")
                  : t("insights:insights_load_more")}
              </ActionLink>
            </>
          )}
        />
      </Loader>
    </div>
  );
};

export default ResponsibilitiesCard;
