import React from "react";
import useTranslationFooter from "./useTranslationFooter";

export default function Careers() {
  const { t, loading: tLoading } = useTranslationFooter();

  const contactEmail = process.env.REACT_APP_INFO_EMAIL;

  const emailLink = <a href={"mailto:"+ contactEmail}>{contactEmail}</a>;

  if (!tLoading) {
    return (
      <div>
        <p>{t("footer:footer_career_msg_1")}</p>
        <p>{t("footer:footer_career_msg_2")}</p>
        <p>{t("footer:footer_career_msg_3", {
          postProcess: "reactPostprocessor",
          emailLink: emailLink
        })}</p>
      </div>
    );
  }

  return null;
}
