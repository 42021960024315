import React from "react";
import PricingTable from "./PricingTable";
import PricingSlot from "./PricingSlot";
import PricingDetail from "./PricingDetail";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "./PricingSection.scss";

export default function PricingSection(props) {
  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "EmployerLandingBlock");

  function onClickEmployerSignUp(event) {
    event.preventDefault();
    RouterUtils.openExternalUrl(ROUTES.EMPLOYER_PORTAL);
  }

  return (
    <div className="PricingSection">
      <PricingTable>
        <PricingSlot
          onClick={(event) => onClickEmployerSignUp(event)}
          buttonText={t("employer-landing:landing_pricing_action_button_label")}
          title={t("employer-landing:landing_pricing_plan_1_label")}
          priceText={t("employer-landing:landing_pricing_plan_1_text")}
        >
          <PricingDetail>
            <b>{t("employer-landing:landing_pricing_plan_1_feature_6_text")}</b>
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_1_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_2_text")}
          </PricingDetail>
        </PricingSlot>
        <PricingSlot
          highlighted
          onClick={(event) => onClickEmployerSignUp(event)}
          buttonText={t("employer-landing:landing_pricing_action_button_label")}
          title={t("employer-landing:landing_pricing_plan_2_label")}
          priceText={t("employer-landing:landing_pricing_plan_2_text")}
        >
          <PricingDetail>
            <b>{t("employer-landing:landing_pricing_plan_2_feature_6_text")}</b>
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_1_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_2_text")}
          </PricingDetail>
        </PricingSlot>
        <PricingSlot
          onClick={(event) => onClickEmployerSignUp(event)}
          buttonText={t("employer-landing:landing_pricing_action_button_label")}
          title={t("employer-landing:landing_pricing_plan_3_label")}
          priceText={t("employer-landing:landing_pricing_plan_3_text")}
        >
          <PricingDetail>
            <b>{t("employer-landing:landing_pricing_plan_3_feature_6_text")}</b>
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_1_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_2_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_4_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_5_text")}
          </PricingDetail>
        </PricingSlot>
        <PricingSlot
          onClick={(event) => onClickEmployerSignUp(event)}
          buttonText={t("employer-landing:landing_pricing_action_button_label")}
          title={t("employer-landing:landing_pricing_plan_4_label")}
          priceText={t("employer-landing:landing_pricing_plan_4_text")}
        >
          <PricingDetail>
            <b>{t("employer-landing:landing_pricing_plan_4_feature_6_text")}</b>
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_1_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_2_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_4_text")}
          </PricingDetail>
          <PricingDetail>
            {t("employer-landing:landing_pricing_plan_feature_5_text")}
          </PricingDetail>
        </PricingSlot>
      </PricingTable>
      <Row>
        <Col className="indication-col">
          {t("employer-landing:landing_pricing_mention")}
        </Col>
      </Row>
    </div>
  );
}
