import React from "react";
import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";

/**
 * Dynamically load translation files pertaining to the dashboard module.
 * @return {*}
 */
const useTranslationInsights = () => {
  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(
    () => ({
      en: import("./locales/en/insights.json"),
      fr: import("./locales/fr/insights.json"),
    }),
    []
  );

  return useModuleTranslation("insights", imports);
};

export default useTranslationInsights;
