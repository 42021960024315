import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SponsoredListBlock.scss";
import useTranslationSponsorship from "../useTranslationSponsorship";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ActionButton from "../../common/widgets/ActionButton";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import SponsoredRecruiterList from "./SponsoredRecruiterList";
import ActionLink from "../../common/widgets/ActionLink";

export default function SponsoredListBlock(props) {
  const { recruiterId } = props;
  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading);

  const history = useHistory();

  const onClickInvite = (event) => {
    event.preventDefault();
    history.push(ROUTES.SPONSORSHIP_INVITE);
  };

  const onClickDetails = (event) => {
    event.preventDefault();
    history.push(ROUTES.SPONSORSHIP);
  };

  return (
    <div className="SponsoredListBlock">
      <Row>
        <Col className="page-title">
          <h1>{t("sponsorship:sponsored_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <ActionButton onClick={onClickInvite}>
            {t("sponsorship:sponsored_page_invite_button_label")}
          </ActionButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <ActionLink onClick={onClickDetails}>{t("sponsorship:sponsored_page_program_details")}</ActionLink>
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <Row>
            <Col>
              <SponsoredRecruiterList recruiterId={recruiterId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
