import React from "react";
import useTranslationDashboard from "../useTranslationDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import "./RecommendCard.scss";
import ActionLink from "../../common/widgets/ActionLink";
import * as ROUTES from "../../../constants/routes";
import {useHistory} from "react-router-dom";

export default function RecommendCard() {
  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "RecommendCard");

  const history = useHistory();

  const browseJob = (event) => {
    event.preventDefault();
    history.push(ROUTES.RECRUITER_JOBS);
  };

  return (
    <div className="RecommendCard">
      <Row className={"title"}>
        <Col>
          <PageSubtitle colTitle={t("dashboard:recruiters_dashboard_recommend_card_title")} />
        </Col>
      </Row>
      <Row>
        <Col className={"content"}>
          <Row>
            <Col>
              <ActionLink className="label-action" onClick={browseJob}>
                {t("dashboard:recruiters_dashboard_browse_jobs_label_button")}
              </ActionLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
