import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import { Col, Row, Form, FormControl, Button } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes";
import "./SearchPane.scss";

const CompareWithPane = (props) => {
  const { keyword } = props;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "CompareWithPane");

  const [queryText, setQueryText] = useState("");

  const history = useHistory();

  const onClickCompare = (event) => {
    event.preventDefault();
    history.push({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS_COMPARISON,
      search: queryString.stringify({
        firstKeyword: keyword,
        secondKeyword: queryText,
      }),
    });
  };

  const compareInsightsPlaceholder = t("insights:insights_get_insights_placeholder");

  return (
    <Form
      className="CompareWithPane"
      onSubmit={(event) => onClickCompare(event)}
    >
      <Row>
        <Col className="form-col-input">
          <FormControl
            type="text"
            placeholder={compareInsightsPlaceholder}
            onChange={(event) => setQueryText(event.target.value)}
            className="input"
            required
          />
        </Col>
        <Col className="form-col-btn">
          <Button className="btn" type="submit">
            {t("insights:insights_compare_insights_button_label")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompareWithPane;
