import React from "react";
import Page from "../../common/page/Page";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationSponsorship from "../useTranslationSponsorship";
import SponsoredListBlock from "./SponsoredListBlock";

export default function SponsoredListPage(/*props*/) {
  const { profile: recruiter } = useAuthUser();
  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="SponsoredListPage">
          <Page.Title
            text={t("sponsorship:sponsored_page_title")}
            loading={tLoading}
          />
          <Page.Content>
            <SponsoredListBlock recruiterId={recruiter._id} />
          </Page.Content>
        </Page>
      )}
    />
  );
}
