import React from "react";
import CloseIcon from "@material-ui/icons//Close";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./FilterOption.scss";

export default function FilterOption(props) {
  const { onClick, index, indexValue, checked, value } = props;

  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "JobSearchPane");

  return (
    <>
      <span className="FilterOption">
        <span className="option-col">{value}</span>
        <span className="icon-col">
          {checked ? (
            <CloseIcon onClick={onClick} className="close-icon" />
          ) : (
            <UncheckedIcon />
          )}
        </span>
      </span>
      {indexValue === index ? (
        ""
      ) : (
        <span className="and-span">{t("common:common_or")}</span>
      )}
    </>
  );
}
