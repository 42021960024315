import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import { Col, Row, Form, FormControl, Button } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes";
import "./SearchPane.scss";

const GetInsightsPane = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "GetInsightsPane");

  const [queryText, setQueryText] = useState("");

  const history = useHistory();

  const onClickGetInsights = (event) => {
    event.preventDefault();
    history.push({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS,
      search: queryString.stringify({
        keyword: queryText,
      }),
    });
  };

  const getInsightsPlaceholder = t("insights:insights_get_insights_placeholder");

  return (
    <Form
      className="GetInsightsPane"
      onSubmit={(event) => onClickGetInsights(event)}
    >
      <Row>
        <Col className="form-col-input">
          <FormControl
            type="text"
            placeholder={getInsightsPlaceholder}
            onChange={(event) => setQueryText(event.target.value)}
            className="input"
            required
          />
        </Col>
        <Col className="form-col-btn">
          <Button type="submit" className="btn">
            {t("insights:insights_get_insights_button_label")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default GetInsightsPane;
