import React from "react";
import FormGroup from "react-bootstrap/esm/FormGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import useMutationCandidateCreate from "./useMutationCandidateCreate";
import useMutationCandidateUpdate from "./useMutationCandidateUpdate";
import ActionLink from "../../common/widgets/ActionLink";
import useContentModal from "../../common/modals/useContentModal";
import CandidatesList from "../view/CandidatesList";
import "./useCandidateSaveInformationForm.scss";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import ValidatedInputEmail from "../../common/widgets/ValidatedInputEmail";
import IndicationRequiredFields from "../../common/widgets/IndicationRequiredFields";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import RemainingApplicationsSection from "./RemainingApplicationsSection";

export default function useCandidateSaveInformationForm(
  candidate,
  recruiterId,
  jobId
) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationCandidateCreate();

  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationCandidateUpdate();
  useShowLoader(tLoading);

  // Errors
  const errors = creationErrors || updateErrors;

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const submit = () => {
    const fields = getValues();
    if (candidate._id) {
      return updateMutate({
        variables: {
          id: candidate._id,
          name: fields.name.trim(),
          phone: fields.phone.trim(),
          email: fields.email.trim(),
        },
      });
    } else {
      return creationMutate({
        variables: {
          recruiterId: candidate.recruiterId,
          name: fields.name.trim(),
          phone: fields.phone.trim(),
          email: fields.email.trim(),
        },
      });
    }
  };

  // use here for disable fields when we have candidate id
  const isReadOnly = () => {
    if (candidate._id) {
      return true;
    }
  };

  // Modal to search candidates form
  const {
    ContentModal: SearchCandidatesModal,
    show: showSearchCandidatesModal,
    hide: hideSearchCandidatesModal,
  } = useContentModal("SearchCandidatesModal");

  const onClickSearchCandidates = () => {
    showSearchCandidatesModal(getSearchCandidates());
  };

  const getSearchCandidates = () => {
    return (
      <CandidatesList
        onClose={hideSearchCandidatesModal}
        recruiterId={recruiterId}
      />
    );
  };

  const form = (
    <>
      {SearchCandidatesModal}
      <div className="CandidateSaveInformationForm">
        {jobId && (
          <Row className="application-count-row">
            <Col>
              <RemainingApplicationsSection
                jobId={jobId}
                recruiterId={recruiterId}
              />
            </Col>
          </Row>
        )}
        <Row className="information_invite_row">
          <Col>
            {t("candidates:candidates_save_information_invite_msg")}
            {jobId && (
              <>
                {t("candidates:candidates_save_information_or_invite_msg")}
                <ActionLink
                  as={"span"}
                  variant="outline-primary"
                  onClick={(event) => onClickSearchCandidates(event)}
                >
                  <span className="candidates-invite-action-link">
                    {t(
                      "candidates:candidates_save_information_select_candidates_invite_msg"
                    )}
                  </span>
                </ActionLink>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="name">
                  <ValidatedInputText
                    name="name"
                    defaultValue={candidate.name}
                    required
                    disabled={isReadOnly()}
                    control={control}
                    className="FormControl"
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_name_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="email">
                  <ValidatedInputEmail
                    className="FormControl"
                    defaultValue={candidate.email}
                    required
                    name="email"
                    control={control}
                    type="email"
                    placeholder={t(
                      "candidates:candidates_create_candidate_email_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="phone">
                  <ValidatedInputText
                    name="phone"
                    className="FormControl"
                    defaultValue={candidate.phone}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_phone_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="indication-row">
              <Col className="indication-col">
                <IndicationRequiredFields />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading || updateLoading,
    errors,
  };
}
