import React from "react";
import Page from "../../common/page/Page";
import BlogCategoryBlock from "./BlogCategoryBlock";

export default function BlogCategoryPage(props) {

  return (
    <Page className="BlogPage">
      <Page.Content className="spaced-children">
        <BlogCategoryBlock />
      </Page.Content>
    </Page>
  );
}
