import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PricingTable.scss";

export default function PricingTable(props) {
  const { children } = props;
  const childrenCols = React.Children.map(children, (child, i) => {
    return (
      <Col key={i} className="slot-col">
        {child}
      </Col>
    );
  });

  return <Row className="PricingTable">{childrenCols}</Row>;
}
