import React from "react";
import useTranslationJobs from "../useTranslationJobs";

export const SORT_ENUM = {
  DATE_POSTED_DESC: "DATE_POSTED_DESC",
  DATE_POSTED_ASC: "DATE_POSTED_ASC",
  COMMISSION_DESC: "COMMISSION_DESC",
  COMMISSION_ASC: "COMMISSION_ASC",
  RELEVANCE: "RELEVANCE"
};

export const jobSortTypes = Object.values(SORT_ENUM);

export function useJobSortLabels() {

  const { t, loading } = useTranslationJobs();

  const getJobSortLabel = React.useCallback(type => {
    if (loading)
      return type;

    switch (type) {
      case SORT_ENUM.DATE_POSTED_DESC:
        return t("jobs:job_sort_date_desc_label");
      case SORT_ENUM.DATE_POSTED_ASC:
        return t("jobs:job_sort_date_asc_label");
      case SORT_ENUM.COMMISSION_DESC:
        return t("jobs:job_sort_commission_desc_label");
      case SORT_ENUM.COMMISSION_ASC:
        return t("jobs:job_sort_commission_asc_label");
      case SORT_ENUM.RELEVANCE:
        return t("jobs:job_sort_relevance_label");
      default:
        return t("jobs:job_sort_date_desc_label");
    }
  }, [t, loading]);

  return ({
    loading,
    getJobSortLabel
  });
}
