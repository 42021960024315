import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterUpdate";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $id: ID!,
    $firstName: String
    $lastName: String
    $phone: String
    $jobTitle: String
    $city: String
    $country: String
    $aboutMe: String
  ) {
    ${QUERY_SCOPE} {
    _id
      ${QUERY_NAME} (
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        phone: $phone,
        jobTitle: $jobTitle,
        city: $city,
        country: $country
        aboutMe: $aboutMe
      ) {
        _id
        firstName
        lastName
        phone
        email
        jobTitle
        city
        country
        aboutMe
      }
    }
  }
`;

export default function useMutationProfileUpdate() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
