import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SponsoredRecruiterSearchResult.scss";
import useTranslationSponsorship from "../useTranslationSponsorship";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import TypeUtils from "../../../utils/TypeUtils";
import DisplaySinceDate from "./DisplaySinceDate";
import StringUtils from "../../../utils/StringUtils";

export default function SponsoredRecruiterSearchResult(props) {
  const { item: recruiter } = props;

  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading, "SponsoredRecruiterSearchResult");

  const name = TypeUtils.arrayRemoveNullOrEmpty([recruiter.firstName, recruiter.lastName]).join(" ");

  return (
    <div className={"SponsoredRecruiterSearchResult"}>
      <Row className="row-container">
        <Col className={"name-col"}>{name}</Col>
        <Col className={"date-col"}>
          <DisplaySinceDate date={recruiter.createdAt}/>
        </Col>
        <Col className="hire-count-col">
          {t("sponsorship:sponsored_details_hires_count", {count: StringUtils.nullToZero(recruiter.hireCount)})}
        </Col>
      </Row>
    </div>
  );
}
