import React from "react";
import TypeUtils from "../../../utils/TypeUtils";

export default function useMultiValueFilter(initialFilterValues, updateSearchQuery, normalizationFunction) {

  const addFilterValue = (value) => {
    // Add new element to a copy of the set
    const selectedValuesSet = new Set(activeFilterValuesSet);
    selectedValuesSet.add(normalizeFilterValue(value.trim()));
    setActiveFilterValuesSet(selectedValuesSet);

    // Update search query
    updateSearchQuery([...selectedValuesSet]);

    // When the user clicks a value while entering a new value, search results are updated to reflect the change
    // in selected values, but they do not reflect the value the user is entering until he/she hits the Add button.
    // Clear the value the user is entering to avoid confusion.
    setInputValue("");

  };

  const removeFilterValue = (value) => {
    // Remove element from a copy of the set
    const selectedValuesSet = new Set(activeFilterValuesSet);
    selectedValuesSet.delete(value);
    setActiveFilterValuesSet(selectedValuesSet);

    // Update search query
    updateSearchQuery([...selectedValuesSet]);

    // When the user clicks a value while entering a new value, search results are updated to reflect the change
    // in selected values, but they do not reflect the value the user is entering until he/she hits the Add button.
    // Clear the value the user is entering to avoid confusion.
    setInputValue("");
  };

  /**
   * Normalize a string to remove close duplicates or any other consideration. If none is provided, do nothing.

   * @param value Filter value to normalize
   * @return {*} Normalized value
   */
  const normalizeFilterValue = (value) => {

    if (normalizationFunction)
      return normalizationFunction(value);
    else
      return value;
  }

  const normalizeFilterValues = (values) => {
    return TypeUtils.ensureArray(values).map(x => normalizeFilterValue(x));
  }

  // Use a Set to hold values so that there are never duplicate values
  const [activeFilterValuesSet, setActiveFilterValuesSet] =
    React.useState(new Set(normalizeFilterValues(initialFilterValues)));

  // Value in the input box
  const [inputValue, setInputValue] = React.useState("");

  // Return an array of active values for the filter and callbacks to add/remove a filter value
  return {
    inputValue,
    setInputValue,
    filterValues: [...activeFilterValuesSet],
    addFilterValue,
    removeFilterValue,
  };
}
