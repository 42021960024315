import "./InsightsNoResultBlock.scss";
import { Row, Col } from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationInsights from "../useTranslationInsights";
import noResultImg from "../../../img/no-result.svg";
import { Image } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";
import ActionLink from "../../common/widgets/ActionLink";
import { useHistory } from "react-router-dom";

const InsightsNoResultBlock = (props) => {
  const { label } = props;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsNoResultBlock");

  const history = useHistory();
  const onClickAnotherSearch = () => {
    history.push({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL,
    });
  };

  return (
    <div className="InsightsNoResultBlock">
      <Row>
        <Col>
          <Image src={noResultImg} />
        </Col>
      </Row>
      <Row>
        <Col className="no-result-label">{label}</Col>
      </Row>
      <Row>
        <Col>
          <ActionLink className="no-result-link" onClick={onClickAnotherSearch}>
            {t("insights:insights_no_result_link_label")}
          </ActionLink>
        </Col>
      </Row>
    </div>
  );
};

export default InsightsNoResultBlock;
