import React from "react";
import Page from "../common/page/Page";
import useTranslationFooter from "./useTranslationFooter";
import ContactUsForm from "./ContactUsForm";

export default function ContactUsPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  return (
    <Page>
      <Page.Title loading={tLoading}>
        {t("footer:footer_contact_us_title")}
      </Page.Title>
      <Page.Content>
        <ContactUsForm />
      </Page.Content>
    </Page>
  );
}
