import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationCreateInternal";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $candidateId: ID!,
        $recruiterId: ID!,
        $jobId: ID!,
        $resumeId: ID,
        $recruiterNote: String
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          candidateId: $candidateId,
          recruiterId: $recruiterId,
          jobId: $jobId,
          resumeId: $resumeId,
          recruiterNote: $recruiterNote
        ) {
          _id
        }
      }
    }
  `;

export default function useMutationApplicationCreate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
