import React from "react";
import Page from "../../common/page/Page";
import CandidateProfileBlock from "./CandidateProfileBlock";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import { useParams } from "react-router";
import useQueryCandidateById from "./useQueryCandidateById";
import WaitForData from "../../common/data-fetching/WaitForData";
import useAuthUser from "../../authentication-no-ui/useAuthUser";


export default function CandidateProfilePage(props) {
  // Fetch candidate from ID provided in the url params.
  const urlParams = useParams();
  const candidateId = urlParams.candidateId;
  const { profile: recruiter } = useAuthUser();

  const {
    data: candidate,
    loading,
    errors,
  } = useQueryCandidateById(candidateId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <Page className="CandidatesPage">
              <Page.Title text={candidate.name} />
              <Page.Content>
                <CandidateProfileBlock candidate={candidate} recruiterId={recruiter._id} />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
