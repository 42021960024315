import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StringUtils from "../../../utils/StringUtils";
import PlaceIcon from "@material-ui/icons/Place";
import BusinessIcon from "@material-ui/icons/Business";
import WorkPermitIcon from "@material-ui/icons/Check";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import {
  formatRequiredTechnicalSkills,
  TechnicalSkillMatchSection,
} from "../resume-matching/TechnicalSkillMatchSection";
import {
  formatRequiredSoftSkills,
  SoftSkillMatchSection,
} from "../resume-matching/SoftSkillMatchSection";
import HtmlUtils from "../../../utils/HtmlUtils";
import SimilarJobsResultList from "../search/SimilarJobsResultList";
import ActionLink from "../../common/widgets/ActionLink";
import ActionButton from "../../common/widgets/ActionButton";
import "./JobBlock.scss";
import WorkModeIcon from "@material-ui/icons/HomeWork";
import { getJobWorkModeLabel } from "../JobWorkModeEnum";
import TypeUtils from "../../../utils/TypeUtils";
import JobStatusEnum from "../../jobs/JobStatusEnum";

export default function JobBlock(props) {
  const { job } = props;
  const isPublished = job.status === JobStatusEnum.PUBLISHED;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchItem");

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([
    job.city,
    job.state,
    job.country,
  ]).join(", ");

  const history = useHistory();

  const onClickRecommend = () => {
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_RECOMMEND, {
        jobId: job._id,
      })
    );
  };

  const requiredTechnicalSkills = formatRequiredTechnicalSkills(
    job.technicalSkills
  );
  const requiredSoftSkills = formatRequiredSoftSkills(job.softSkills);

  return (
    <div className="JobBlock">
      <Row>
        <Col>
          <div className="Job-container">
            <Row>
              <Col>{!isPublished && <span className={"archived-message"}>{t("jobs:job_archived_message")}</span>}</Col>
            </Row>
            <Row>
              <Col>
                <h1>{job.title}</h1>
              </Col>
            </Row>
            <Row>
              <Col className="icon-col-container">
                <Row>
                  <Col>
                    <Row>
                      <Col className="iconCol">
                        <BusinessIcon />
                      </Col>
                      <Col>{job.organization && job.organization.company}</Col>
                    </Row>
                    {!StringUtils.isNullOrEmpty(addressLine) && (
                      <Row>
                        <Col className="iconCol">
                          <PlaceIcon />
                        </Col>
                        <Col>{addressLine}</Col>
                      </Row>
                    )}
                    {!StringUtils.isNullOrEmpty(job.workMode) && (
                      <Row>
                        <Col className="iconCol">
                          <WorkModeIcon />
                        </Col>
                        <Col>{getJobWorkModeLabel(t, job.workMode)}</Col>
                      </Row>
                    )}
                    {job.isWorkPermitRequired && (
                      <Row>
                        <Col className="iconCol">
                          <WorkPermitIcon />
                        </Col>
                        <Col>{t("jobs:job_work_permit_required_label")}</Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col className="commission-col">
                <Row>
                  <Col className="section-title">
                    {t("jobs:job_commission_title")}
                  </Col>
                </Row>
                <Row>
                  <Col className="commission-amount-col">
                    <span className="commission-amount-span">
                      ${job.commission}
                    </span>
                  </Col>
                </Row>
                {isPublished && (
                  <Row>
                    <Col>
                      <ActionLink onClick={onClickRecommend}>
                        {t("jobs:job_recommend_action")}
                      </ActionLink>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {requiredTechnicalSkills.length > 0 && (
              <Row className="rowContainer">
                <Col className="Skills-col">
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="section-title">
                      {t("jobs:job_technical_skills_label")}
                      <TechnicalSkillMatchSection
                        allSkills={requiredTechnicalSkills}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {requiredSoftSkills.length > 0 && (
              <Row className="rowContainer">
                <Col className="Skills-col">
                  <Row>
                    <Col className="section-title">
                      {t("jobs:job_soft_skills_label")}
                      <SoftSkillMatchSection allSkills={requiredSoftSkills} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className="rowContainer">
              <Col>{HtmlUtils.htmlToComponents(job.description)}</Col>
            </Row>
            {isPublished && (
              <Row className="action-row">
                <Col>
                  <ActionButton onClick={onClickRecommend}>
                    {t("jobs:job_recommend_action")}
                  </ActionButton>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row className="rowContainer">
        <Col className="job-similar-col">
          <SimilarJobsResultList
            query={[job.title].concat(job.technicalSkills).slice(0, 10)}
            currentJobId={job._id}
          />
        </Col>
      </Row>
    </div>
  );
}
