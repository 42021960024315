import React from "react";
import Page from "../common/page/Page";
import useTranslationFooter from "./useTranslationFooter";
import Careers from "./Careers";


export default function CareersPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  return (
    
      <Page className="CareerPage">
        <Page.Title loading={tLoading}>
        {t("footer:footer_career_title")}
        </Page.Title>
        <Page.Content>
          <Careers/>
        </Page.Content>
      </Page>
    
  );
}

