import {useContext} from "react";
import ReactDOM from "react-dom";
import HeaderContainerRefContext from "./HeaderContainerRefContext";

/**
 * Displays the header component in an html node somewhere else in the React tree, using a portal to the target container div.
 * If reference to target node is null, does not display anything.
 * @return {*}
 * @constructor
 */
export default function HeaderPortal(props) {

  const {component} = props;
  const HeaderContainerRef = useContext(HeaderContainerRefContext);

  // RENDER

  return HeaderContainerRef.current !== null ? ReactDOM.createPortal(component, HeaderContainerRef.current) : null;
}
