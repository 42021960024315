import React from "react";
import AuthUserContext from "./AuthUserContext";
import useFirebaseAuth from "./useFirebaseAuth";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import WaitForData from "../common/data-fetching/WaitForData";

/**
 * Context provider that can be consumed by withAuthUser to get current logged user with its profile.
 *
 * Current user is an object with the following properties:
 *
 * <ul>
 *   <li>authenticated: true if user is authenticated, false otherwise (user is not null when not authenticated)
 *   <li>id: user ID from the authentication service (different from profile ID)
 *   <li>email: user email from authentication service (might be different from profile email)
 *   <li>refetch: refetch profile callback</li>
 *   <li>displayName: name gotten from some authentication providers
 *   <li>profile: if authenticated: all properties coming from our own database of users;
 *                if not authenticated: some properties may be set throughout navigation</li>
 * </ul>
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function AuthUserProvider(props) {

  const {useQueryProfile, children} = props;

  const firebaseAuth = useFirebaseAuth();
  const email = firebaseAuth.authenticated ? firebaseAuth.user.email : null;
  const id = firebaseAuth.authenticated ? firebaseAuth.user.id : null;
  const displayName = firebaseAuth.authenticated ? firebaseAuth.user["displayName"] : null;
  const {data: profile, loading, error, refetch} = useQueryProfile(email);

  useShowLoader(loading, "AuthUserProvider");

  // RENDER

  const authUser = {
    authenticated: firebaseAuth.authenticated,
    email,
    id,
    profile,
    refetch,
    displayName
  }

  return (
    <WaitForData loading={loading} error={error} onLoaded={() =>
      <AuthUserContext.Provider value={authUser}>
        {children}
      </AuthUserContext.Provider>
    }/>
  );
}
