import React from "react";
import TypeUtils from "../../../utils/TypeUtils";
import PillList from "../../common/widgets/PillList";
import "./SkillMatchBlock.scss";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";

export default function SkillMatchBlock(props) {

  const {t, loading: tLoading} = useTranslationJobs();
  const {skills, matchFunction} = props;
  const hasSkills = TypeUtils.ensureArray(skills).length > 0;

  useShowLoader(tLoading, "SkillMatchBlock");

  // RENDER

  return (
    <div className="SkillMatchBlock spaced-children">
      {!hasSkills &&
      <span className="no-skills-label">{t("jobs:job_no_skill_label")}</span>
      }
      {hasSkills &&
      <PillList items={skills} variant="primary" altVariant="success"
                altTest={(item) => matchFunction(item)}/>
      }
    </div>
  );
}
