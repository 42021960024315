import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterSponsoredSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($recruiterId: ID!, $cursor: Int, $pageSize: Int, $filter: RecruiterSponsoredFilterInput, $sort: RecruiterSponsoredSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(recruiterId: $recruiterId, cursor: $cursor, pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          firstName
          lastName
          hireCount
          createdAt
        }
        nextCursor
        hasNextPage
      }
    }
  }
`;

export default function useQuerySponsoredRecruiterSearch(recruiterId, cursor, pageSize, filter, sort) {
  const variables = { recruiterId, cursor, pageSize, filter, sort };
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
