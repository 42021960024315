import React from "react";
import * as ROUTES from "../../constants/routes";
import Image from "react-bootstrap/Image";
import {Link, useParams} from "react-router-dom";
import "./MenuLogo.scss";
import useQueryOrganizationByNameUrl from "../organization/view/useQueryOrganizationByNameUrl";
import WaitForData from "../common/data-fetching/WaitForData";
import RouterUtils from "../../utils/RouterUtils";

export default function InternalMenuLogo(/*props*/) {

  // Fetch company name from ID provided in the url params.
  const urlParams = useParams();
  const companyName = urlParams.companyName;

  const {
    data: organization,
    loading: organizationLoading,
    errors: organizationErrors,
  } = useQueryOrganizationByNameUrl(companyName);

  return (
    <div className="MenuLogo">
      <WaitForData
        loading={organizationLoading}
        errors={organizationErrors}
        onLoaded={() =>
          <>
            {organization.companyLogoUrl &&
            <Link to={RouterUtils.injectParamsInRoute(ROUTES.INTERNAL_EMPLOYER, {name: companyName})}>
              <Image src={organization.companyLogoUrl} className="logo"/>
            </Link>
            }
          </>
        }/>
    </div>
  );
};
