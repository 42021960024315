import React from "react";
import WaitForData from "../../common/data-fetching/WaitForData";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router";
import ShareBar from "../../common/components/ShareBar";
import HtmlUtils from "../../../utils/HtmlUtils";
import ArticleSeo from "../../common/seo/ArticleSeo";
import LatestJobsCard from "./LatestJobsCard";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import useContentful from "./useContentful";
import "./BlogPostBlock.scss";
import BlogUtils from "./BlogUtils";

export default function BlogPostBlock() {
  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const title = urlParams.title;

  const { loading, data, errors, getContentEntries } = useContentful();

  React.useEffect(() => {
    getContentEntries({
      "fields.url[match]": title,
      content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_BLOG_TYPE,
    });
  }, [title, getContentEntries]);

  const domainName = window.location.hostname;

  const createUrl = (category, url) =>
    domainName + BlogUtils.getBlogPostRoute(category, url);

  return (
    <div>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <>
            <ArticleSeo
              title={data.items[0].fields.title}
              resume={data.items[0].fields.resume}
              author={data.items[0].fields.author}
              featuredImage={
                data.items[0].fields.featuredImage
                  ? "https:" +
                    data.items[0].fields.featuredImage.fields.file.url
                  : ""
              }
              datePublished={data.items[0].fields.date}
              dateModified={data.items[0].fields.date}
              url={createUrl(
                data.items[0].fields.categoryEntity.fields.categoryUrl,
                data.items[0].fields.url
              )}
            />
            <article className="BlogPostBlock">
              <Row>
                <Col className="blog-post-title">
                  <h1>{data.items[0].fields.title}</h1>
                </Col>
              </Row>
              <Row className="post-information">
                <Col>
                  <ShareBar
                    title={data.items[0].fields.title}
                    tags={[data.items[0].fields.title]}
                    url={createUrl(
                      data.items[0].fields.categoryEntity.fields.categoryUrl,
                      data.items[0].fields.url
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image
                    className="image-row"
                    alt={
                      data.items[0].fields.featuredImage
                        ? data.items[0].fields.featuredImage.fields.description
                        : ""
                    }
                    src={
                      data.items[0].fields.featuredImage
                        ? data.items[0].fields.featuredImage.fields.file.url
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <section>
                    {HtmlUtils.htmlToComponents(
                      documentToHtmlString(data.items[0].fields.description)
                    )}
                  </section>
                </Col>
              </Row>
            </article>
            <div className="latest-job">
              <Row className="latest-job-row">
                <Col>
                  <LatestJobsCard />
                </Col>
              </Row>
            </div>
          </>
        )}
      />
    </div>
  );
}
