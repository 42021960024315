import React from "react";
import useFirebase from "../infra-no-ui/firebase/useFirebase";
import Firebase from "../infra-no-ui/firebase/Firebase";
import useTranslationAuthenticationNoUi from "./useTranslationAuthenticationNoUi";
import useIsMounted from "../infra-no-ui/react-helpers/useIsMounted";

export default function useSignIn() {

  const {t, loading: tLoading} = useTranslationAuthenticationNoUi();
  const firebase = useFirebase();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Avoid warnings about changing state of an unmounted component by keeping track of whether the component is mounted
  const isMounted = useIsMounted();

  // Sign in callback (use useCallback to avoid re-rendering of useSignOut consumers)
  const signIn = React.useCallback((email, password) => {
    setLoading(true);
    setError(null);

    // Perform sign in
    firebase
      .doSignInWithEmailAndPassword(email.trim(), password)
      .catch(error => {
        if (isMounted) {
          const message = t(Firebase.getErrorTranslation(t, error.code)) + " (code: " + error.code + ")";
          setError(message);
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });
  }, [firebase, isMounted, t]);

  return ({
    signIn,
    loading, // We mean the loading state of the signin action, not the loading of translation
    ready: !tLoading, // The component is not ready to be used by caller until the translation is loaded
    error
  });
}
