import React from "react";
import { useQuery } from "@apollo/client";
import usePreviousState from "../../infra-no-ui/react-helpers/usePreviousState";
import QueryUtils from "./QueryUtils";

/**
 * Executes a query to retrieve a fixed number of items, and provides a callback to fetch more results.
 * The query must support pagination ("pageSize" and "cursor" as input, and "hasNextPage", "nodes" and "nextCursor" as output).
 *
 * The results are reset when the query options change (eg. the search parameters change).
 *
 * @param query
 * @param queryScope
 * @param queryName
 * @param initialSize Number of elements to fetch on first load
 * @param incrementSize Number of elements to fetch when fetching more
 * @param options Query options, including search params
 * @return {{data: (ReadonlyArray<*>|ProfileNode[]|[]|[string]|{value}|*[]), hasNextPage: (boolean), loadMore: *, loadingMore: *, loading: *, error: *}}
 */
export default function usePagedQuery(query, queryScope, queryName, initialSize, incrementSize, options = {}) {

  // On first render, supplement option variables with a cursor and pageSize
  const [queryOptions, setQueryOptions] = React.useState(makeQueryOptions(options, 0, initialSize));

  const previousOptions = usePreviousState(options);

  // Cached results. Use refs instead of states so that we don't return outdated values while waiting for setState to complete
  const cachedNodesRef = React.useRef([]);
  const cachedHasNextPage = React.useRef(true);

  // Calculate the query options to pass to useQuery, depending on the previous query options
  // and the desired number of elements we want. Make sure we return the same object if we don't want to fire a new query.
  const calculateQueryOptions = React.useCallback((previousQueryOptions, pageSize) => {

    if (options !== previousOptions) {

      // If search options (particularly the search params) have changed, we want to start a new search.
      // Reset cached results and trigger a new search
      cachedNodesRef.current = [];
      cachedHasNextPage.current = true;

      return makeQueryOptions(options, 0, pageSize);

    } else if (pageSize > cachedNodesRef.current.length) {

      // If initial size has changed and is more than the items already fetched, fetch the missing items
      return makeQueryOptions(options, cachedNodesRef.current.length, pageSize - cachedNodesRef.current.length);

    } else if (pageSize < cachedNodesRef.current.length) {

      // Remove items from already fetched items
      cachedNodesRef.current.splice(
        pageSize
      );
      return previousQueryOptions;

    } else {

      // initial size is equal to the items fetched so far, don't fire query (don't change query settings)
      return previousQueryOptions;

    }
  }, [options, previousOptions]);

  // Reset query options when initialSize changes or options change (through calculateQueryOptions)
  React.useEffect(() => {
    setQueryOptions(previousQueryOptions => calculateQueryOptions(previousQueryOptions, initialSize));
  }, [calculateQueryOptions, initialSize]);

  // Load more callback to pass to caller if needed
  const loadMore = React.useCallback(() => {
    // Increment the total number of results we have by "incrementSize"
    setQueryOptions(previousQueryOptions => calculateQueryOptions(previousQueryOptions, cachedNodesRef.current.length + incrementSize));
  }, [calculateQueryOptions, incrementSize]);

  // Launch query
  const { data, loading, error } = useQuery(query, queryOptions);

  // Memoize return value so that it does not change on every intermediate step of useQuery
  return React.useMemo(() => {
    const newData = QueryUtils.removeQueryScopeAndName(data, queryScope, queryName);

    // When loading is over and there are no errors, data returned is actual data. However, the hook might be called in
    // that state multiple times, so we can't just concat new nodes to cached ones every time loading is over and there are
    // no errors. We have to replace (or add) nodes starting at "cursor" with the ones received.
    if (!loading && !error) {
      const newNodes =  newData ? newData.nodes : [];

      cachedNodesRef.current.splice(
        queryOptions.variables.cursor,
        queryOptions.variables.pageSize,
        ...newNodes
      );

      // As soon as we learn that there are no more pages, remember that
      if (newData && !newData.hasNextPage) {
        cachedHasNextPage.current = false;
      }
    }

    return {
      loading: loading && queryOptions.variables.cursor === 0, // The query was launched after a change in the params and it is waiting for data
      loadingMore: loading && queryOptions.variables.cursor > 0, // The query was launched after a loadMore event and it is waiting for data
      error,
      data: cachedNodesRef.current, // Beware that some elements may be null or undefined when the sequence of calls leaves holes
      hasNextPage: cachedHasNextPage.current, // There is a next page that can be retrieved with a call to loadMore
      loadMore, // Callback to get the next results
    };
  }, [data, loading, error, queryOptions, loadMore, queryName, queryScope]);

}

/**
 * Make a copy of the query options, with new cursor and pageSize variables
 * @param options Current query options
 * @param cursor New cursor
 * @param pageSize New page size
 * @returns {*&{variables: (*&{cursor, pageSize})}} New query options
 */
function makeQueryOptions(options, cursor, pageSize) {
  return {
    ...options,
    variables: {
      ...options.variables,
      cursor,
      pageSize,
    }
  }
}
