import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import useQueryApplicationByJobIdAndRecruiterIdRemainingCount from "./useQueryApplicationByJobIdAndRecruiterIdRemainingCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import Loader from "../../common/loading-widgets/Loader";
import InformationDisplay from "../../common/widgets/InformationDisplay";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";

export default function RemainingApplicationsSection(props) {
  const { jobId, recruiterId } = props;
  const {
    data: remainingApplicationsCount,
    loading,
    errors,
  } = useQueryApplicationByJobIdAndRecruiterIdRemainingCount(
    jobId,
    recruiterId
  );
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "RemainingApplicationsSection");

  return (
    <Loader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <InformationDisplay
            information={t(
              "candidates:candidates_job_remaining_recommendations_msg",
              {
                count: remainingApplicationsCount,
              }
            )}
            hoverInformation = {t(
              "candidates:candidates_job_remaining_recommendations_msg_hover")}
          />
        )}
      />
    </Loader>
  );
}
